// redux/connectionSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getVariable } from '../../utils/nomal';

const connectionSlice = createSlice({
  name: 'connection',
  initialState: {
    connection: '',
    chatRoomIdActive: '',
    toggleAccordion: false,
    endChat: false,
    fullName: false,
    pushData: getVariable('messPush')
      ? JSON.parse(getVariable('messPush'))
      : [],
    showPushToast: false,
    tabChat: 'live',
    mediaGallery: {},
    listMess: [],
  },
  reducers: {
    setConnection: (state, action) => {
      state.connection = action.payload;
    },
    setChatRoomActive: (state, action) => {
      state.chatRoomIdActive = action.payload;
    },
    clearConnection: (state) => {
      state.connection = null;
    },
    setToggleAccordion: (state, action) => {
      state.toggleAccordion = action.payload;
    },
    setEndChat: (state, action) => {
      state.endChat = action.payload;
    },
    setUpdateFullName: (state, action) => {
      state.fullName = action.payload;
    },
    setUpdateRoomIdActive: (state, action) => {
      state.roomIdActive = action.payload;
    },
    setPushData: (state, action) => {
      state.pushData = action.payload;
    },
    setShowPushToast: (state, action) => {
      state.showPushToast = action.payload;
    },
    changeTabChat: (state, action) => {
      state.tabChat = action.payload;
    },
    setMediaGallery: (state, action) => {
      state.mediaGallery = action.payload;
    },
    setListMess: (state, action) => {
      state.listMess = action.payload;
    },
  },
});

export const {
  setConnection,
  clearConnection,
  setChatRoomActive,
  setToggleAccordion,
  setEndChat,
  setUpdateFullName,
  setUpdateRoomIdActive,
  setPushData,
  setShowPushToast,
  changeTabChat,
  setMediaGallery,
  setListMess,
} = connectionSlice.actions;
export default connectionSlice.reducer;
