import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import createAxiosClient from '../utils/axiosClient';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function AvgResponse() {
  const navigate = useNavigate();
  const axiosClient = createAxiosClient(navigate);
  const [avgResponse, setAvgResponse] = useState([]);

  useEffect(() => {
    const fetchChatData = async () => {
      try {
        const body = {
          Type: 'responsetime',
          LastDay: '7',
        };
        const result = await axiosClient.post('/dashboard', body);
        if (result && result.Responsetime) {
          const processedData = result.Responsetime.map((item) => ({
            ...item,
            Value: parseFloat(item.Value.replace('s', '')),
          }));
          setAvgResponse(processedData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchChatData();
  }, []);

  const data = {
    labels: avgResponse.map((item) => item.Day),
    datasets: [
      {
        label: 'Response Time',
        data: avgResponse.map((item) => item.Value),
        fill: true,
        borderColor: '#4686E5',
        backgroundColor: '#c7daf7',
        tension: 0.5,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            family: 'Inter',
            size: 13,
            weight: 500,
          },
        },
        grid: {
          borderDash: [5, 5],
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          borderDash: [5, 5],
        },
        ticks: {
          callback: function (value) {
            return value + ' s';
          },
          font: {
            family: 'Inter',
            size: 13,
            weight: 400,
          },
        },
      },
    },
  };

  return (
    <div style={{ height: '330px', width: '100%', position: 'relative' }}>
      <Line data={data} options={options} />
    </div>
  );
}
