import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PushItem from './PushItem';
import { setPushData } from '../Redux/connectionSlice';
import { setVariable } from '../../utils/nomal';
import emptyPush from '../../assets/image/no-notifications.jpg';
import { useTranslation } from "react-i18next";
export default function PushList(props) {
  const { t } = useTranslation();
  const { pushData } = useSelector((state) => state.connection);
  const dispatch = useDispatch();
  const markAsRead = (data, targetId) => {
    return data.map((dateObj) => {
      // Lấy khóa của đối tượng dateObj (ví dụ "31/10/2024")
      const [dateKey] = Object.keys(dateObj);
      return {
        // Tạo bản sao của đối tượng với khóa ngày
        [dateKey]: dateObj[dateKey].map((notification) => {
          if (notification.id === targetId) {
            // Tạo một bản sao của notification với thuộc tính readed được cập nhật
            return { ...notification, readed: true };
          }
          return notification;
        }),
      };
    });
  };

  const readPush = (id) => {
    const data = pushData;
    const newPushData = markAsRead(data, id);
    dispatch(setPushData(newPushData));
    setVariable('messPush', JSON.stringify(newPushData));
  };

  const markAllAsRead = (data) => {
    return data.map((dateObj) => {
      // Lấy khóa của đối tượng dateObj (ví dụ "31/10/2024")
      const [dateKey] = Object.keys(dateObj);
      return {
        // Tạo bản sao của đối tượng với khóa ngày
        [dateKey]: dateObj[dateKey].map((notification) => {
          // Cập nhật tất cả các thông báo là đã đọc
          return { ...notification, readed: true };
        }),
      };
    });
  };

  const readPushAll = () => {
    const data = pushData;
    const newPushData = markAllAsRead(data);
    dispatch(setPushData(newPushData));
    setVariable('messPush', JSON.stringify(newPushData));
  };

  const renderItem = (v) => {
    return (
      v.length > 0 &&
      v.map((j) => {
        return (
          //   console.log(j),
          <PushItem
            title={j.title}
            body={j.body}
            time={j.time}
            isread={j.readed}
            id={j.id}
            date={j.date}
            image={j.image}
            readPush={readPush}
          />
        );
      })
    );
  };

  return (
    <div>
      <div className="flex flex-col justify-between bg-[#F0F3F7] sticky top-0">
        <div className="flex justify-between px-5 py-4">
          <h3 className="font-medium text-gray-700">{t("notifications")}</h3>
          <CloseIcon
            onClick={() => props.showPush(false)}
            className="text-gray-400 cursor-pointer"
          />
        </div>
        <div
          className="flex items-center px-5 py-2 gap-2 bg-white cursor-pointer"
          onClick={() => readPushAll()}
        >
          <DoneAllIcon
            className="text-black"
            style={{ fontSize: 14, color: '#2F8DE3' }}
          />
          <h4 className="text-[#2F8DE3] text-[14px] font-normal">
          {t("markAllAsRead")}
          </h4>
        </div>
      </div>
      <div className="overflow-auto h-full">
        {pushData.length > 0 ? (
          pushData.map((v) => {
            return (
              <>
                {/* <div className="mb-3 font-bold text-sm">{Object.keys(v)}</div> */}
                {renderItem(v[Object.keys(v)])}
              </>
            );
          })
        ) : (
          <div>
            <img src={emptyPush} alt="noimage" />
          </div>
        )}
      </div>
    </div>
  );
}
