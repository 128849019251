import React, { useCallback, useState, useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import createAxiosClient from '../../utils/axiosClient';
import { useDispatch, useSelector } from 'react-redux';
import { setChatRoomActive, setMediaGallery } from '../Redux/connectionSlice';
export default function ChatItem({
  chatInfo,
  onChatItemClick,
  sourceTab,
  onTab,
  setClickStartChat,
  setTypeChatToggle,
  typeChatToggle,
  index,
  connection,
}) {
  const dispatch = useDispatch();
  const [MessUnread, setMessUnread] = useState(chatInfo.MessUnread);
  const roomIdActive = useSelector(
    (state) => state.connection.chatRoomIdActive
  );
  const currentTabChat = useSelector((state) => state.connection.tabChat);
  useEffect(() => {
    if (roomIdActive && roomIdActive === chatInfo.RoomId) {
      chatItemRef.current?.classList.add('active');
      fetchChatData();
      if (currentTabChat === 'live') {
        fetchMediaData();
      }
    } else {
      chatItemRef.current?.classList.remove('active');
    }
  }, [roomIdActive, chatInfo.RoomId]);

  const chatItemRef = useRef(null);
  const [isFirstItemClicked, setIsFirstItemClicked] = useState(false);
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);
  const navigate = useNavigate();
  const axiosClient = createAxiosClient(navigate, dispatch);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileOrTablet(window.matchMedia('(max-width: 1024px)').matches);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setMessUnread(chatInfo.MessUnread);
  }, [chatInfo]);

  const fetchChatData = async () => {
    const body = {
      roomId: chatInfo.RoomId,
    };
    const result = await axiosClient.post('/chatroom/info', body);
    onChatItemClick(result);
    dispatch(setChatRoomActive(chatInfo.RoomId));
    // dispatch(setUpdateRoomIdActive(chatInfo.RoomId));
    localStorage.setItem('roomIdActive', chatInfo.RoomId);
    onTab(sourceTab);

    if (typeof setClickStartChat === 'function') {
      setClickStartChat(false);
    } else {
      console.error('setClickStartChat is not a function');
    }
  };

  const fetchMediaData = async () => {
    try {
      const body = {
        RoomId: chatInfo.RoomId,
      };
      const result = await axiosClient.post('/chatroom/moreinfo', body);
      result && dispatch(setMediaGallery(result));
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const handleClickItem = useCallback(
    (e) => {
      if (isMobileOrTablet) {
        if (index === 0 && !isFirstItemClicked) {
          setIsFirstItemClicked(true);
        } else {
          setTypeChatToggle(!typeChatToggle);
        }
      }
      const activeItem = document.querySelector('.chat-item-wraper.active');
      if (activeItem) {
        activeItem.classList.remove('active');
      }
      e.currentTarget.classList.add('active');

      MessUnread > 0 && setMessUnread(0);

      fetchChatData();
      if (currentTabChat === 'live') {
        fetchMediaData();
      }
    },
    [
      MessUnread,
      onChatItemClick,
      sourceTab,
      onTab,
      typeChatToggle,
      index,
      isFirstItemClicked,
      isMobileOrTablet,
      chatInfo,
      connection,
    ]
  );

  return (
    <div
      className="space-x-3 chat-item-wraper"
      onClick={handleClickItem}
      ref={chatItemRef}
      id={chatInfo.RoomId}
    >
      <div className="relative chat-item-avatar">
        <div class="flex justify-center items-center w-10 h-10 border-0 rounded-full">
          <img
            src={chatInfo.Image}
            alt="avatar-mage"
            class="w-10 h-10 rounded-full"
          />
        </div>
        <div class="flex justify-center items-center w-[18px] h-[18px] rounded-full absolute bottom-0 right-0 bg-white">
          <img
            src={chatInfo.ImageChannel}
            alt="avatar-image-channel"
            class="w-[18px] h-[18px] rounded-full avatar-image-channel"
          />
        </div>
      </div>
      <div className="chat-item-info">
        <p className={MessUnread > 0 ? 'chat-name unread' : 'chat-name'}>
          {chatInfo.Name}
        </p>
        <div className={MessUnread > 0 ? 'mask-mess unread' : 'mask-mess'}>
          <span>{chatInfo.Type === 'sender' ? 'admin: ' : ''} </span>
          <span>{chatInfo.MaskMess}</span>
        </div>
      </div>
      {MessUnread > 0 && (
        <div className="chat-unread">
          {MessUnread > 99 ? '99+' : MessUnread}
        </div>
      )}
    </div>
  );
}
