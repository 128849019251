import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import createAxiosClient from '../utils/axiosClient';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function SuccessConver() {
  const navigate = useNavigate();
  const axiosClient = createAxiosClient(navigate);
  const [successConver, setSuccessConver] = useState([]);

  useEffect(() => {
    const fetchChatData = async () => {
      try {
        const body = {
          Type: 'successconversation',
          LastDay: '7',
        };
        const result = await axiosClient.post('/dashboard', body);

        if (result && result.Successconversation) {
          // Ensure values are parsed as numbers
          const processedData = result.Successconversation.map((item) => ({
            ...item,
            Value: parseInt(item.Value, 10),
          }));
          setSuccessConver(processedData);
        }
      } catch (error) {
        console.error('Error fetching success conversation data:', error);
        // toast.error(
        //   `Error fetching data: ${error.response?.data || error.message}`
        // );
      }
    };
    fetchChatData();
  }, []);

  const data = {
    labels: successConver.map((item) => item.Day),
    datasets: [
      {
        label: 'Success Conversations',
        data: successConver.map((item) => item.Value),
        borderWidth: 1,
        barThickness: 30,
        backgroundColor: '#c6dcfd',
        hoverBackgroundColor: '#428BF9',
        borderRadius: 4,
        datalabels: {
          color: '#52525B',
          anchor: 'end',
          align: 'top',
          font: {
            weight: 'bold',
          },
        },
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        titleColor: '#000',
        bodyColor: '#000',
        borderColor: '#ddd',
        borderWidth: 1,
        padding: 10,
        displayColors: false,
        callbacks: {
          label: function (context) {
            return `${context.parsed.y} conversations`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: 'Inter',
            size: 13,
            weight: 500,
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          borderDash: [5, 5],
        },
        ticks: {
          font: {
            family: 'Inter',
            size: 13,
            weight: 400,
            color: '#606060',
          },
        },
      },
    },
  };

  return (
    <div style={{ height: '330px', width: '100%', position: 'relative' }}>
      <Bar data={data} options={options}></Bar>
    </div>
  );
}
