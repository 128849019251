// components/PendingChatComponent.js
import React from 'react';
import ChatItem from './ChatItem';

const PendingChatComponent = ({ pendingChats, onChatItemClick, sourceTab, onTab, clickStartChat, setClickStartChat, setTypeChatToggle, typeChatToggle, connection  }) => (
    <div>
        <ul>
            {pendingChats && pendingChats.Data && pendingChats.Data.length > 0 ? (
                pendingChats.Data.map((chat, index) => (
                    <ChatItem 
                        key={index} 
                        chatInfo={chat} 
                        onChatItemClick={onChatItemClick} 
                        sourceTab={sourceTab} 
                        onTab={onTab} 
                        clickStartChat={clickStartChat} 
                        setClickStartChat={setClickStartChat} 
                        setTypeChatToggle={setTypeChatToggle} 
                        typeChatToggle={typeChatToggle} 
                        connection={connection}
                    />
                ))
            ) : (
                <div className='chat-item-wraper-chat'>
                    <div className='chat-loading'>
                        {pendingChats && pendingChats.Data && pendingChats.Data.length === 0 ? (
                            <div className='text-[15px] text-[#656565] font-medium'>
                                No messages available
                            </div>
                        ) : (
                            <span className="loader-chat"></span>
                        )}
                    </div>
                </div>
            )}
        </ul>
    </div>
);

export default PendingChatComponent;
