import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';
import LiveChatComponent from './LiveChatComponent';
import PendingChatComponent from './PendingChatComponent';
import SearchChat from './SearchChat';
import { useNavigate } from 'react-router-dom';
import createAxiosClient from '../../utils/axiosClient';
import { useDispatch, useSelector } from 'react-redux';
import {
  setEndChat,
  changeTabChat,
  setListMess,
  setChatRoomActive,
} from '../Redux/connectionSlice';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
const ChatLeft = forwardRef(
  (
    {
      onChatItemClick,
      onTabChange,
      onTab,
      clickStartChat,
      setClickStartChat,
      typeChatToggle,
      setTypeChatToggle,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const roomId = searchParams.get('roomId');
    const group = searchParams.get('group');

    const { connection, tabChat } = useSelector((state) => state.connection);
    const isEndChat = useSelector((state) => state.connection.endChat);
    const listMess = useSelector((state) => state.connection.listMess);
    const [liveChats, setLiveChats] = useState({});
    const [pendingChats, setPendingChats] = useState({});
    const [totalMessUnRead, setTotalMessUnRead] = useState();
    const [searchText, setSearchText] = useState('');
    const navigate = useNavigate();
    const axiosClient = createAxiosClient(navigate);
    const dispatch = useDispatch();

    const fetchChatData = async () => {
      const body = {
        SearchText: searchText,
      };
      const result = await axiosClient.post('/chatroom/byteller', body);
      dispatch(setListMess(result));
      const { LiveChat, PendingChat } = result;

      if (LiveChat) {
        setLiveChats(LiveChat);
      }

      if (PendingChat) {
        setPendingChats(PendingChat);
        if (PendingChat.Data !== undefined) {
          setTotalMessUnRead(PendingChat.Data.length);
        }
      }
    };

    useEffect(() => {
      fetchChatData();
    }, [searchText]);

    useEffect(() => {
      if (roomId) {
        if (group === 'Createconversation') {
          dispatch(changeTabChat('pending'));
        } else if (group === 'Chat') {
          dispatch(changeTabChat('live'));
        }
        dispatch(setChatRoomActive(roomId));
      }
    }, []);

    useEffect(() => {
      if (isEndChat && tabChat === 'live') {
        fetchChatData();
        dispatch(setEndChat(false));
      }
      if (connection) {
        connection.on('ConversationCreated', () => {
          fetchChatData();
        });
      }

      return () => {
        if (connection) {
          connection.off('ConversationCreated');
        }
      };
    }, [connection, isEndChat]);

    useEffect(() => {
      const { LiveChat, PendingChat } = listMess;

      if (LiveChat) {
        setLiveChats(LiveChat);
      }

      if (PendingChat) {
        setPendingChats(PendingChat);
        if (PendingChat.Data !== undefined) {
          setTotalMessUnRead(PendingChat.Data.length);
        }
      }
    }, [listMess]);

    const handleTabChange = (tab) => {
      dispatch(changeTabChat(tab));
      onTabChange(tab);
    };

    const handleSearch = (text) => {
      setSearchText(text);
    };

    useEffect(() => {
      if (clickStartChat) {
        dispatch(changeTabChat('live'));
        fetchChatData();
      }
    }, [clickStartChat]);

    const handleToggleChat = () => {
      setTypeChatToggle(!typeChatToggle);
    };

    useImperativeHandle(ref, () => ({
      fetchChatData,
    }));

    useEffect(() => {
      if (connection) {
        connection.on('FetchData', () => {
          setTimeout(() => {
            fetchChatData();
          }, 2000);
        });
      }

      return () => {
        if (connection) {
          connection.off('FetchData');
        }
      };
    }, [connection]);

    return (
      <>
        <div
          className={`chat-left ${
            typeChatToggle ? 'active' : ''
          } transition-all duration-300 ease-in-out`}
        >
          <SearchChat
            setTypeChatToggle={setTypeChatToggle}
            typeChatToggle={typeChatToggle}
            value={searchText}
            onChange={handleSearch}
          />
          <div className="wraper-button-tab">
            <button
              onClick={() => handleTabChange('live')}
              className={`tab ${tabChat === 'live' ? 'active' : ''}`}
            >
               {t("livechat")}
            </button>
            <button
              onClick={() => handleTabChange('pending')}
              className={`tab ${tabChat === 'pending' ? 'active' : ''}`}
            >
              {t("pendingChat")}
              {totalMessUnRead > 0 && (
                <span className="total-unread-count">
                  {totalMessUnRead > 99 ? '99+' : totalMessUnRead}
                </span>
              )}
            </button>
          </div>

          <div className="chat-list custom-scrollbar">
            {tabChat === 'live' && (
              <LiveChatComponent
                liveChats={liveChats}
                onChatItemClick={onChatItemClick}
                sourceTab={tabChat}
                onTab={onTab}
                setTypeChatToggle={setTypeChatToggle}
                typeChatToggle={typeChatToggle}
              />
            )}
            {tabChat === 'pending' && (
              <PendingChatComponent
                pendingChats={pendingChats}
                onChatItemClick={onChatItemClick}
                sourceTab={tabChat}
                onTab={onTab}
                clickStartChat={clickStartChat}
                setClickStartChat={setClickStartChat}
                setTypeChatToggle={setTypeChatToggle}
                typeChatToggle={typeChatToggle}
                connection={connection}
              />
            )}
          </div>
        </div>
        <div
          className={`overlayChatLeft ${typeChatToggle ? '' : 'active'}`}
          onClick={() => handleToggleChat()}
        ></div>
      </>
    );
  }
);

export default ChatLeft;
