import React from 'react'
import ChatItem from './ChatItem'
import dayjs from 'dayjs';
import NoResult from '../../assets/image/nomessfilter.png'
import { useTranslation } from "react-i18next";

export default function ChatListHistory({ chatData, dateSearch, onChatItemClick, typeChatToggle, setTypeChatToggle, isFilterOpen, }) {
  const { t } = useTranslation();
  return (
    <div className={`px-4 list-history ${isFilterOpen ? "" : "active"} overflow-y-auto pb-5 custom-scrollbar`} >
      <div className='flex items-center px-4'>
        <span className='text-[#848484] text-[16px] font-medium'>{dateSearch.toDate && dayjs(dateSearch.toDate).format('dddd, D MMM')}</span>
      </div>
      <ul>
        {chatData && chatData.HistoryChatRoom && chatData.HistoryChatRoom.length > 0 ? (
          chatData.HistoryChatRoom.map((chatRoom, roomIndex) =>
            chatRoom.History.map((chatHistoryItem, historyIndex) => (
              <ChatItem
                key={`${roomIndex}-${historyIndex}`} 
                chatInfo={chatHistoryItem}  
                onChatItemClick={onChatItemClick}
                typeChatToggle={typeChatToggle}
                setTypeChatToggle={setTypeChatToggle}
              />
            ))
          )
        ) : (
          <div className='chat-item-wraper flex flex-col gap-1 mt-5'>
            <img src={NoResult} alt="" />
            <div className='text-[15px] text-[#656565] font-medium'>{t("noResult")}</div>
          </div>
        )}
      </ul>
    </div>
  )
}
