import createAxiosClient from '../utils/axiosClient';
 
const FirebaseConfig = () => {
  // Thêm cấu hình Firebase nếu cần thiết
};
 
const axiosClient = createAxiosClient();
 
export async function getFirebaseConfigdll(handleSuccess, handleError) {
  try {
    const body = {
       Name: "FireBase",
    };

    const response = await axiosClient.post(`variables/getfirebaseinfo`, body);
    if (typeof handleSuccess === 'function') {
      return handleSuccess(response);
    } else {
      console.warn("handleSuccess is not a function.");
      return null;
    }
  } catch (error) {
 
    if (typeof handleError === 'function') {
        return handleError(error);
      } else {
        console.warn("handleError is not a function.");
        return null;
      }
  }
}
 
export default FirebaseConfig;