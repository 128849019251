import React, { useState, useRef } from 'react';
import ChatLeft from './ChatLeft';
import ChatDetail from './ChatDetail';
import '../../css/ChatScreen.css';

import axios from 'axios';
import UserInfo from './UserInfo';
import { useNavigate } from 'react-router-dom';
import createAxiosClient from '../../utils/axiosClient';

export default function ChatScreen() {
  const [selectedChatDetail, setSelectedChatDetail] = useState(null);
  const [activeTab, setActiveTab] = useState('live');
  const [currentTab, setCurrenTab] = useState('');
  const [clickStartChat, setClickStartChat] = useState(false);
  const [typeChatToggle, setTypeChatToggle] = useState(true);
  const [toggleUserInfo, setToggleUserInfo] = useState(true);
  const navigate = useNavigate();
  const axiosClient = createAxiosClient(navigate);
  const chatLeftRef = useRef();

  const handleChatItemClick = (chatDetailData) => {
    setSelectedChatDetail(chatDetailData);
  };
  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  };
  const handleGetTab = (tab) => {
    setCurrenTab(tab);
  };

  const handleRefreshLiveChats = () => {
    if (chatLeftRef.current) {
      chatLeftRef.current.fetchChatData();
    }
  };

  const handleStartChat = async () => {
    if (selectedChatDetail) {
      const body = {
        SearchText: '',
      };
      const result = await axiosClient.post('/chatroom/byteller', body);
      setClickStartChat(true);
      setCurrenTab('live');
      setActiveTab('live');
    }
  };

  const handleChangeName = async (RoomId, NickName) => {
    try {
      const body = { RoomId: RoomId, NickName: NickName };
      const result = await axiosClient.post('/chatroom/changename', body);
    } catch (error) {
      console.error('Error changing name:', error);
    }
  };

  return (
    <div id="chat-screen" className="chat-main  overflow-hidden">
      <ChatLeft
        ref={chatLeftRef}
        onChatItemClick={handleChatItemClick}
        onTabChange={handleTabChange}
        onTab={handleGetTab}
        clickStartChat={clickStartChat}
        setClickStartChat={setClickStartChat}
        typeChatToggle={typeChatToggle}
        setTypeChatToggle={setTypeChatToggle}
      />
      <ChatDetail
        data={selectedChatDetail}
        activeTab={activeTab}
        dataTab={currentTab}
        onStartChat={handleStartChat}
        typeChatToggle={typeChatToggle}
        setTypeChatToggle={setTypeChatToggle}
        toggleUserInfo={toggleUserInfo}
        setToggleUserInfo={setToggleUserInfo}
        onRefreshLiveChats={handleRefreshLiveChats}
      />
      <UserInfo
        data={selectedChatDetail}
        toggleUserInfo={toggleUserInfo}
        setToggleUserInfo={setToggleUserInfo}
        isHistory={false}
        setSelectedChatDetail={setSelectedChatDetail}
        handleChangeName={handleChangeName}
      />
    </div>
  );
}
