import React, { useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PhotoUpload from '../../assets/image/photo_upload.png';
import SendMessIcon from '../../assets/image/sendmessicon.png';
import SendMessDisabled from '../../assets/image/sendmessdisabeldicon.png';
import '../../css/ChatSendMessage.css';
import { useNavigate } from 'react-router-dom';
import createAxiosClient from '../../utils/axiosClient';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import { setMediaGallery } from '../Redux/connectionSlice';

export default function ChatSendMessage({ data }) {
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [textValue, setTextValue] = useState('');
  const fileInputRef = useRef(null);
  const connection = useSelector((state) => state.connection.connection);
  const roomID = data.RoomId;
  const navigate = useNavigate();
  const axiosClient = createAxiosClient(navigate);
  const [mediaIds, setMediaIds] = useState([]);
  const removeSelectedFile = async (fileName, mediaId) => {
    try {
      const body = {
        LstMediaId: [mediaId],
      };
      const result = await axiosClient.post('/delete', body);
      if (result) {
        setSelectedFiles((prevFiles) =>
          prevFiles.filter((file) => file.Name !== fileName)
        );
        setMediaIds((prevMediaIds) =>
          prevMediaIds.filter((id) => id !== mediaId)
        );
      }
    } catch (error) {
      console.error('Failed to delete media:', error);
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileChange = useCallback(
    async (event) => {
      const files = Array.from(event.target.files);
      if (files.length === 0) {
        setMediaIds([]);
        return false; // Indicate no files to upload
      }

      const newFiles = files.filter(
        (file) =>
          !selectedFiles.some((selectedFile) => selectedFile.Name === file.name)
      );
      if (newFiles.length === 0) {
        return false; // No new files to upload
      }

      const body = await Promise.all(
        newFiles.map(async (file) => ({
          Name: file.name,
          Type: file.type,
          File: await convertFileToBase64(file),
        }))
      );

      try {
        const result = await axiosClient.post('/upload', body);

        if (result && result.MediaIds) {
          setMediaIds((prevMediaIds) => [...prevMediaIds, ...result.MediaIds]);
          setSelectedFiles((prevFiles) => [...prevFiles, ...body]);
          fileInputRef.current.value = '';
          return true; // Indicate success
        }
      } catch (error) {
        console.error('Upload error:', error);
        return false; // Indicate failure
      }
    },
    [selectedFiles]
  );

  const fetchMediaData = async () => {
    try {
      const body = {
        RoomId: roomID,
      };
      const result = await axiosClient.post('/chatroom/moreinfo', body);
      result && dispatch(setMediaGallery(result));
    } catch (error) {
      console.error('Error fetching media data:', error);
    }
  };

  const handleChangeAndGetMedia = async (event) => {
    const uploadSuccess = await handleFileChange(event);
    // await new Promise((resolve) => setTimeout(resolve, 300));
    // if (uploadSuccess) {
    //   await fetchMediaData();
    // }
  };

  const handleImageClick = useCallback(() => {
    fileInputRef.current.click();
  }, []);

  const handleTextChange = useCallback((e) => {
    setTextValue(e.target.value);
  }, []);

  const sendMessage = async () => {
    try {
      const roomId = roomID;
      const message = textValue;
      const roleType = 'teller';
      const metadata = {};
      await connection.send(
        'SendMessage',
        roomId,
        message,
        roleType,
        mediaIds,
        metadata
      );
      setSelectedFiles([]);
      setMediaIds([]);
      setTextValue('');
      fetchMediaData();
    } catch (e) {}
  };
  const typing = useCallback(
    async (e) => {
      if (connection) {
        try {
          await connection.send('Typing', connection.connectionId);
        } catch (e) {}
      }
    },
    [connection]
  );

  const stopTyping = useCallback(
    async (e) => {
      if (connection) {
        try {
          await connection.send('StopTyping', connection.connectionId);
        } catch (e) {}
      }
    },
    [connection]
  );

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      if (textValue.trim() !== '' || selectedFiles.length > 0) {
        e.preventDefault();
        sendMessage();
      }
    }
  };

  return (
    <div className="chat-action-wrapper">
      <div className="choose-file">
        <input
          type="file"
          onChange={handleFileChange}
          className="input-upload-file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept="*/*"
          multiple
        />
        <img src={PhotoUpload} alt="Upload" onClick={handleImageClick} />
      </div>
      <div className="type-message">
        {selectedFiles.length > 0 && (
          <div className="uploaded-images">
            {selectedFiles.map((file, index) => (
              <div key={index} className="preview-item">
                {file.Type.startsWith('image/') ? (
                  <img
                    src={`data:${file.Type};base64,${file.File}`}
                    alt={`Selected preview ${index}`}
                    className="preview-image"
                  />
                ) : (
                  <div className="file-info">
                    <DescriptionIcon />
                    <span className="file-name">{file.Name}</span>
                  </div>
                )}
                <CloseIcon
                  className="remove-icon"
                  onClick={() => removeSelectedFile(file.Name, mediaIds[index])}
                />
              </div>
            ))}
          </div>
        )}
        <input
          type="text"
          placeholder="Enter message here"
          className="input-type-message"
          value={textValue}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
          onKeyUp={stopTyping}
        />
      </div>

      <button
        className={`button-action ${
          !textValue && selectedFiles.length === 0 ? 'disabled' : ''
        }`}
        onClick={sendMessage}
        disabled={!textValue && selectedFiles.length === 0}
      >
        <img
          src={
            !textValue && selectedFiles.length === 0
              ? SendMessDisabled
              : SendMessIcon
          }
          alt="Send"
        />
      </button>
    </div>
  );
}
