import React from 'react';
import axios from 'axios';
import { useSelector,  useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setChatRoomActive } from '../Redux/connectionSlice';
import createAxiosClient from "../../utils/axiosClient";
import { useTranslation } from "react-i18next";
export default function ChatApproveMessage({ onStartChat, data }) {
    const { t } = useTranslation();
    const connection = useSelector(state => state.connection.connection);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const axiosClient = createAxiosClient(navigate);

    const handleApproveChat = async () => {
        const body = {
            roomId: data.RoomId,  
            connectionId: connection.connectionId 
        };
          const result = await axiosClient.post("chatroom/joinroom", body);
          dispatch(setChatRoomActive(data.RoomId)) 
          onStartChat();
    };
    

    return (
        <div className='chat-approve-wrapper'>
            <p>{t("areYouSureYouWantToJoinThisChat")}</p>
            <button
                onClick={handleApproveChat} 
                className='button-approve-message'
            >
               {t("startNow")}
            </button>
        </div>
    );
}
