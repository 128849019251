import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children }) => {
  const token = useSelector((state) => state.auth.token); // Kiểm tra token trong Redux
  // Nếu không có token, redirect tới trang login
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  // Nếu có token, render nội dung của route
  return children;
};

export default PrivateRoute;
