import { getVariable, setVariable, isIdExistingMess } from './nomal';
import { store } from '../app/store';
import {
  setShowPushToast,
  setPushData,
} from '../components/Redux/connectionSlice';
import { toast } from 'react-toastify';
import PushToast from '../components/Push/PushToast';
const utils = {};
export const OnReceivedPush = (payload) => {
  // debugger
  const pathName = window.location.pathname;
  const date = payload.datetime.split(' ');
  const currentMessPush = getVariable('messPush')
    ? JSON.parse(getVariable('messPush'))
    : [];
  const mess = {
    date: date[0],
    time: date[1],
    pushType: payload.group,
    body: payload.body,
    link: payload.link,
    image: payload.imgurl,
    title: payload.title,
    readed: false,
    id: payload.id,
    action: payload.action,
  };

  if (!isIdExistingMess(currentMessPush, mess.id) && payload.group !== 'Chat') {
    store.dispatch(setShowPushToast(false));
    // Thêm thông báo mới vào mảng tương ứng với ngày
    let foundDate = false;
    for (let i = 0; i < currentMessPush.length; i++) {
      if (currentMessPush[i].hasOwnProperty(date[0])) {
        currentMessPush[i][date[0]].unshift(mess);
        foundDate = true;
        break;
      }
    }
    if (!foundDate) {
      currentMessPush.unshift({ [date[0]]: [mess] });
    }

    // Kiểm tra và loại bỏ các phần tử cũ nếu tổng số phần tử vượt quá 100
    let totalCount = 0;
    for (let i = 0; i < currentMessPush.length; i++) {
      totalCount +=
        currentMessPush[i][Object.keys(currentMessPush[i])[0]].length;
    }

    while (totalCount > 100) {
      let oldestDate = currentMessPush[currentMessPush.length - 1];
      let oldestMessages = oldestDate[Object.keys(oldestDate)[0]];
      oldestMessages.pop(); // Xóa đi phần tử cũ nhất trong mảng của ngày đó
      if (oldestMessages.length === 0) {
        currentMessPush.pop(); // Nếu mảng của ngày đó trở thành rỗng, loại bỏ ngày đó khỏi mảng chính
      }
      totalCount--;
    }
    setVariable('messPush', JSON.stringify(currentMessPush));
    store.dispatch(setPushData(currentMessPush));
    toast.info(
      <PushToast
        title={mess.title}
        image={mess.image}
        body={mess.body}
        group={mess.pushType}
        link={mess.link}
      />,
      {
        position: 'bottom-right',
        icon: false,
      }
    );
  } else {
    const activeRoomid = store.getState()?.connection?.chatRoomIdActive;
    console.log(pathName);
    if (activeRoomid !== mess.link || pathName !== '/Chat') {
      toast.info(
        <PushToast
          title={mess.title}
          image={mess.image}
          body={mess.body}
          group={mess.pushType}
          link={mess.link}
        />,
        {
          position: 'bottom-right',
          icon: false,
        }
      );
    }
  }
};

export default utils;
