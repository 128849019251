import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/Login.css";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import createAxiosClient from "../../utils/axiosClient";
import "../../css/ChangePassword.css";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
const ChangePassword = () => {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const axiosClient = createAxiosClient(navigate);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleBlur = (field) => {
    if (!field.value) {
      setError((prevError) => ({
        ...prevError,
        [field.name]: `${field.label} is required`,
      }));
    } else {
      setError((prevError) => ({ ...prevError, [field.name]: "" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    setError({
      oldPassword: !oldPassword ? t("currentPasswordIsRequired") : "",
      newPassword: !newPassword ? t("newPasswordIsRequired") : "",
      confirmPassword: !confirmPassword ? t("confirmPasswordIsRequire") : "",
    });

    if (newPassword !== confirmPassword) {
      setError((prevError) => ({
        ...prevError,
        confirmPassword: "New password and confirm password do not match.",
      }));
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const hashedOldPassword = CryptoJS.SHA256(oldPassword).toString();
      const hashedNewPassword = CryptoJS.SHA256(newPassword).toString();

      const response = await axiosClient.post(`user/changepass`, {
        OldPass: hashedOldPassword,
        NewPass: hashedNewPassword,
      });

      toast.success("Change success");
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setSubmitted(false);
    } catch (err) {
      setError((prevError) => ({
        ...prevError,
        general: "Failed to change password",
      }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="change-password">
      <div className="change-password-wrapper flex">
        <div className="form-changepassword flex flex-col gap-6">
          <form
            className="form-main-changepassword flex flex-col gap-7"
            onSubmit={handleSubmit}
            noValidate
          >
            <div>
              <div className="mb-5">
                <div className="flex items-center change-input">
                  <label
                    htmlFor="old-password"
                    className="text-[14px] text-black font-semibold mb-1 inline-block change-input-label"
                  >
                    {t("currentPassword")}
                  </label>
                  <div className="custom-input-changpass">
                    <input
                      id="old-password"
                      name={`old-password-${Math.random()}`}
                      type={showOldPassword ? "text" : "password"}
                      placeholder={t("enterOldPassword")}
                      Confirm
                      autoComplete="new-password"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      onBlur={() =>
                        handleBlur({
                          value: oldPassword,
                          name: "oldPassword",
                          label: "Current password",
                        })
                      }
                      required
                    />
                    {showOldPassword ? (
                      <VisibilityOutlinedIcon
                        className="input-icon-change-password"
                        onClick={() => setShowOldPassword(false)}
                        style={{
                          cursor: "pointer",
                          color: "rgba(102, 112, 133, 1)",
                        }}
                      />
                    ) : (
                      <VisibilityOffOutlinedIcon
                        className="input-icon-change-password"
                        onClick={() => setShowOldPassword(true)}
                        style={{
                          cursor: "pointer",
                          color: "rgba(102, 112, 133, 1)",
                        }}
                      />
                    )}
                    {error.oldPassword && (
                      <label className="label-required">
                        {error.oldPassword}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <div className="flex items-center change-input">
                  <label
                    htmlFor="new-password"
                    className="text-[14px] text-black font-semibold mb-1 inline-block change-input-label"
                  >
                    {t("newPassword")}
                  </label>
                  <div className="custom-input-changpass">
                    <input
                      id="new-password"
                      type={showNewPassword ? "text" : "password"}
                      placeholder={t("enterNewPassword")}
                      autoComplete="new-password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      onBlur={() =>
                        handleBlur({
                          value: newPassword,
                          name: "newPassword",
                          label: "New password",
                        })
                      }
                    />
                    {showNewPassword ? (
                      <VisibilityOutlinedIcon
                        className="input-icon-change-password"
                        onClick={() => setShowNewPassword(false)}
                        style={{
                          cursor: "pointer",
                          color: "rgba(102, 112, 133, 1)",
                        }}
                      />
                    ) : (
                      <VisibilityOffOutlinedIcon
                        className="input-icon-change-password"
                        onClick={() => setShowNewPassword(true)}
                        style={{
                          cursor: "pointer",
                          color: "rgba(102, 112, 133, 1)",
                        }}
                      />
                    )}
                    {error.newPassword && (
                      <label className="label-required">
                        {error.newPassword}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <div className="flex items-center change-input">
                  <label
                    htmlFor="confirm-password"
                    className="text-[14px] text-black font-semibold mb-1 inline-block change-input-label"
                  >
                    {t("confirmPassword")}
                  </label>
                  <div className="custom-input-changpass">
                    <input
                      id="confirm-password"
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder={t("confirmNewPassword")}
                      autoComplete="new-password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      onBlur={() => {
                        if (!confirmPassword) {
                          setError((prevError) => ({
                            ...prevError,
                            confirmPassword: t("confirmPasswordIsRequire")
                          }));
                        } else if (confirmPassword !== newPassword) {
                          setError((prevError) => ({
                            ...prevError,
                            confirmPassword: t("confirmPasswordDoesNotMatchNewPassword"),
                          }));
                        } else {
                          setError((prevError) => ({
                            ...prevError,
                            confirmPassword: "",
                          }));
                        }
                      }}
                      required
                    />
                    {showConfirmPassword ? (
                      <VisibilityOutlinedIcon
                        className="input-icon-change-password"
                        onClick={() => setShowConfirmPassword(false)}
                        style={{
                          cursor: "pointer",
                          color: "rgba(102, 112, 133, 1)",
                        }}
                      />
                    ) : (
                      <VisibilityOffOutlinedIcon
                        className="input-icon-change-password"
                        onClick={() => setShowConfirmPassword(true)}
                        style={{
                          cursor: "pointer",
                          color: "rgba(102, 112, 133, 1)",
                        }}
                      />
                    )}
                    {error.confirmPassword && (
                      <label className="label-required">
                        {error.confirmPassword}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: loading ? "" : "rgba(100, 149, 245, 1)",
                color: "#FFF",
                fontWeight: "400",
                height: "40px",
                width: "150px",
                borderRadius: "5px",
                textTransform: "none",
                boxShadow: "none",
                "&:hover": {
                  backgroundColor: loading
                    ? "rgba(100, 149, 245, 1)"
                    : "rgba(100, 149, 245, 1)",
                },
                "&:disabled": {
                  backgroundColor: "#DDDDDD",
                  color: "rgba(47, 141, 227, 1)",
                },
              }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t("saveChanges")
              )}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
