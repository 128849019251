import React, { useState, useEffect } from "react";
import Close from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import dayjs from "dayjs";
import ChatListHistory from "./ChatListHistory";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useNavigate } from "react-router-dom";
import createAxiosClient from "../../utils/axiosClient";
import "../../css/ChatLeft.css";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTranslation } from "react-i18next";
export default function ChatLeft({
  onChatItemClick,
  typeChatToggle,
  setTypeChatToggle,
}) {
  const { t } = useTranslation();
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [chatData, setChatData] = useState([]);
  const [dateSearch, setDateSearch] = useState({});
  const [showFilter, setShowFilter] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showPagination, setShowPagination] = useState(false);
  const navigate = useNavigate();
  const axiosClient = createAxiosClient(navigate);
  const toggleChatVisibility = () => {
    setTypeChatToggle(!typeChatToggle);
  };
  useEffect(() => {
    localStorage.removeItem("nameUser");
  }, []);

  const handleDateChange = (setter) => (newValue) => {
    setter(newValue);
  };

  const handleToggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleClearAll = () => {
    setDateFrom(null);
    setDateTo(null);
    setSearchName("");
  };

  const fetchChatData = async (
    name,
    fromDate,
    toDate,
    pageIndex = 1,
    pageSize = 15
  ) => {
    try {
      const body = {
        UserName: searchName,
        FromDate: fromDate,
        ToDate: toDate,
        PageIndex: pageIndex,
        PageSize: pageSize,
      };
      const result = await axiosClient.post("/ChatRoom/history", body);
      setShowPagination(true);
      setChatData(result);
      setTotalPages(result.Pages);
      setDateSearch({
        fromDate: fromDate ? fromDate.format("YYYY-MM-DD 00:00:00") : "",
        toDate: toDate ? toDate.format("YYYY-MM-DD 00:00:00") : "",
      });
    } catch (error) {
      toast.error(
        "Error fetching chat data: " + (error.message || "Unknown error"),
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };

  const handleSearch = () => {
    if (dateFrom && dateTo && dayjs(dateFrom).isAfter(dayjs(dateTo))) {
      toast.error("From date cannot be after To date", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    fetchChatData(searchName, dateFrom, dateTo, currentPage);
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
      fetchChatData(searchName, dateFrom, dateTo, newPage);
    }
  };

  const goToFirstPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
      fetchChatData(searchName, dateFrom, dateTo, 1);
    }
  };

  const goToLastPage = () => {
    if (currentPage !== totalPages) {
      setCurrentPage(totalPages);
      fetchChatData(searchName, dateFrom, dateTo, totalPages);
    }
  };

  return (
    <>
      <div
        className={`chat-left overflow-hidden ${
          typeChatToggle ? "active" : ""
        } transition-all duration-300 ease-in-out`}
      >
        <div className="filter-wrapper px-4 py-4 flex flex-col gap-4">
          <div className="advance-filter flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div onClick={handleToggleFilter}>
                {showFilter ? <Close /> : <FilterListIcon />}
              </div>
              <span className="text-lg font-semibold">
                {" "}
                {t("advanceFilter")}
              </span>
            </div>
            <button
              className={`text-[#E84580] text-sm font-medium ${
                showFilter ? "block" : "hidden"
              }`}
              onClick={handleClearAll}
            >
              {t("clearAll")}
            </button>
          </div>

          <div
            className={`filter-body flex flex-col gap-4 ${
              showFilter ? "block" : "hidden"
            }`}
          >
            <div className="w-full">
              <TextField
                id="outlined-size-small"
                label={t("searchByName")}
                value={searchName}
                className="bg-[#F3F6F9] w-full text-sm font-medium rounded"
                onChange={(e) => setSearchName(e.target.value)}
                size="small"
              />
            </div>

            <div className="flex items-center gap-2">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  label={t("fromDate")}
                  value={dateFrom}
                  onChange={handleDateChange(setDateFrom)}
                  inputFormat="DD/MM/YYYY HH:mm:ss"
                  slotProps={{
                    textField: {
                      size: "small",
                      sx: {
                        width: "200px", // Adjust width as needed
                      },
                    },
                  }}
                />

                <DatePicker
                  label={t("toDate")}
                  value={dateTo}
                  onChange={handleDateChange(setDateTo)}
                  inputFormat="DD/MM/YYYY HH:mm:ss"
                  slotProps={{
                    textField: {
                      size: "small",
                      sx: {
                        width: "200px", // Adjust width as needed
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
            <div>
              <button
                className="w-full rounded-md bg-[#24BBA7] text-white text-sm font-normal py-2"
                onClick={handleSearch}
              >
                {t("search")}
              </button>
            </div>
          </div>
        </div>

        <ChatListHistory
          chatData={chatData}
          dateSearch={dateSearch}
          onChatItemClick={onChatItemClick}
          typeChatToggle={typeChatToggle}
          setTypeChatToggle={setTypeChatToggle}
          isFilterOpen={showFilter}
        />
        {showPagination && (
          <div className="pagination-controls flex justify-center items-center my-2">
            {/* <button
      className={`pagination-button ${currentPage === 1 ? 'disabled' : ''}`}
      onClick={goToFirstPage}
      disabled={currentPage === 1}
    >
      <FirstPageIcon />
    </button> */}
            <button
              className={`pagination-button ${
                currentPage === 1 ? "disabled" : ""
              }`}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <FirstPageIcon />
            </button>
            <span className="pagination-info">{`${currentPage} / ${totalPages}`}</span>
            <button
              className={`pagination-button ${
                currentPage === totalPages ? "disabled" : ""
              }`}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <LastPageIcon />
            </button>
            {/* <button
      className={`pagination-button ${currentPage === totalPages ? 'disabled' : ''}`}
      onClick={goToLastPage}
      disabled={currentPage === totalPages}
    >
      <LastPageIcon />
    </button> */}
          </div>
        )}
      </div>
      <div
        className={`overlayHistory ${typeChatToggle ? "" : "active"}`}
        onClick={() => toggleChatVisibility()}
      ></div>
    </>
  );
}
