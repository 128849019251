import React, { useEffect, useState } from "react";
import england from "../../assets/image/england.png";
import burmese from "../../assets/image/brumese.png";
import vietnam from "../../assets/image/vietnam.png";
import "../../css/Header.css";
import SegmentOutlinedIcon from "@mui/icons-material/SegmentOutlined";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import { useDispatch, useSelector } from "react-redux";
import { setToggleAccordion } from "../Redux/connectionSlice";
import "../../css/NavBar.css";
import PushList from "../Push/PushList";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
export default function Navbar({ sidebarToggle, setSidebarToggle }) {
  const { t } = useTranslation();
  const [showPushList, setShowPushList] = useState(false);
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const toggleAccordion = useSelector(
    (state) => state.connection.toggleAccordion
  );
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18next.changeLanguage(savedLanguage);  // Đổi ngôn ngữ khi có giá trị lưu trữ
      setCurrentLanguage(savedLanguage);  // Cập nhật state currentLanguage
    }
  }, []);
  const dispatch = useDispatch();

  const handleClickSideBar = () => {
    setSidebarToggle(!sidebarToggle);

    if (toggleAccordion) {
      dispatch(setToggleAccordion(false));
    }
  };
  const closePushList = (e) => {
    e.stopPropagation();
    setShowPushList(false);
  };
  const toggleLanguageModal = (e) => {
    e.stopPropagation();
    setShowLanguageModal(!showLanguageModal);
  };
  
  
  useEffect(() => {
    const handleClickOutside = () => setShowLanguageModal(false);
    if (showLanguageModal) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showLanguageModal]);
 
  const getFlag = (language) => {
    switch (language) {
      case "en":
        return england;
      case "br":
        return burmese;
      case "vi":
        return vietnam;
      default:
        return england;
    }
  };
  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng); 
    setShowLanguageModal(false); 
   setCurrentLanguage(lng);
   localStorage.setItem("language", lng);
  };
  return (
    <nav className="px-9 py-3 flex justify-between nav-bar-wrapper">
      <div className="nav-bar-left">
        <div className="flex items-center" onClick={handleClickSideBar}>
          {sidebarToggle ? (
            <SegmentOutlinedIcon className="text-[#3A3A3A] me-4 !cursor-pointer" />
          ) : (
            <ArrowRightAltOutlinedIcon className="text-[#3A3A3A] me-4 !cursor-pointer" />
          )}
          <span className="font-semibold text-lg">{t("tellerPortal")}</span>
        </div>
      </div>
      <div className="flex items-center gap-x-5 nav-bar-right">
        <div
          onClick={() => setShowPushList(!showPushList)}
          className="icon-circle cursor-pointer"
        >
          <NotificationsNoneIcon />
        </div>
        <div
          className="icon-circle cursor-pointer"
          onClick={toggleLanguageModal}
        >
           <img src={getFlag(currentLanguage)} alt="Current Language" className="flag" />
        </div>
      </div>
      {showLanguageModal && (
        <div
          className="absolute top-[50px] right-[40px] w-[200px] bg-white shadow-md rounded-lg p-4 z-20"
          style={{
            boxShadow: "0 0 1px 1px rgba(0, 0, 0, 0.2)",
            zIndex: 1000, 
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <ul>
            <li
              className="cursor-pointer hover:bg-gray-200 px-2 py-1 flex items-center gap-2"
              onClick={() => changeLanguage("en")}
            >
              <img
                src={england}
                alt="Change Language"
                className="flag w-5 h-5"
              />
              {t("english")}
            </li>
            <li
              className="cursor-pointer hover:bg-gray-200 px-2 py-1 flex items-center gap-2"
              onClick={() => changeLanguage("br")}
            >
              <img
                src={burmese}
                alt="Change Language"
                className="flag w-5 h-5"
              />
              {t("burmese")}
            </li>
            <li
              className="cursor-pointer hover:bg-gray-200 px-2 py-1 flex items-center gap-2"
              onClick={() => changeLanguage("vi")}
            >
              <img
                src={vietnam}
                alt="Change Language"
                className="flag w-5 h-5"
              />
              {t("vietnam")}
            </li>
          </ul>
        </div>
      )}

      {showPushList && (
        <div
          onClick={(e) => closePushList(e)}
          className="fixed top-[60px] bottom-0 right-[8px] left-0 z-50"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="absolute top-0 right-0 bottom-[20px] w-[400px] z-10 bg-white shadow-xl overflow-auto no-scrollbar rounded-xl"
          >
            <PushList showPush={setShowPushList} />
          </div>
        </div>
      )}
    </nav>
  );
}
