import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../css/Login.css';
import Logo from '../../assets/image/logologin.png';
import { toast } from 'react-toastify';

const ResetPassword = () => {
  const [email, setEmail] = useState(''); // Thay đổi từ username sang email
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        'http://172.26.28.225:6005/api/auth/reset-password',
        {
          email,
        }
      );

      toast.success('Password reset link sent to your email.');
      navigate('/login');
    } catch (err) {
      setError('Failed to send reset link');
      toast.error('Error sending reset link');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="login-wrapper flex">
        <div className="form-login flex flex-col gap-6">
          <div>
            <img src={Logo} alt="" className="my-0 mx-auto" />
          </div>
          <form
            className="form-main-login flex flex-col gap-7"
            onSubmit={handleSubmit}
          >
            <p className="text-[20px] text-white font-bold text-center uppercase">
              Reset Password
            </p>
            <div>
              <div className="mb-5">
                <label
                  htmlFor="email"
                  className="text-[14px] text-white font-semibold mb-1 inline-block"
                >
                  Your Email
                </label>
                <div className="custom-input-login">
                  <input
                    id="email"
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <button
              className="w-full h-[50px] bg-[#A0DBED] rounded-xl text-[#6979B4] font-semibold"
              type="submit"
              disabled={loading}
            >
              {loading ? 'Sending...' : 'Send Reset Link'}
            </button>

            {error && toast.error(`Error: ${error}`)}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
