import React, { useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import Draggable from 'react-draggable';
import Avatar from '../../assets/image/Avatar.png';
import CallEndIcon from '@mui/icons-material/CallEnd';
import RemoveIcon from '@mui/icons-material/Remove';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
const AudioCall = ({
  imageUser,
  nameUser,
  callStatus,
  isCalling,
  isMicOn,
  endCall,
  toggleMic,
  callDuration,
}) => {
  const formatDuration = (duration) => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${
      seconds < 10 ? '0' : ''
    }${seconds}`;
  };
  const [isMinimized, setIsMinimized] = useState(false);
  const handleMinimize = () => {
    setIsMinimized(true);
  };
  const handleRestore = () => {
    setIsMinimized(false);
  };
  return (
    <>
      <Draggable handle=".draggable-handle">
        <div
          className="audio-call draggable-handle"
          style={{
            zIndex: isMinimized ? -1000 : 1000,
          }}
        >
          <div
            className=""
            style={{
              display: 'flex',
              padding: '5px',
              color: 'white',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div style={{ marginLeft: '20px' }} className="draggable-handle">
                {/* <img
                  src={`data:image/png;base64,${imageUser || Avatar}`}
                  alt=""
                  style={{ width: "155px", borderRadius: "100px" }}
                /> */}
                <svg
                  width="150"
                  height="150"
                  viewBox="0 0 259 259"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="129.5" cy="129.5" r="129.5" fill="#6E83B7" />
                  <g filter="url(#filter0_d_1585_10403)">
                    <circle cx="128.5" cy="77.5" r="52.5" fill="#EDEFF1" />
                  </g>
                  <g filter="url(#filter1_d_1585_10403)">
                    <ellipse
                      cx="129"
                      cy="182.5"
                      rx="91"
                      ry="52.5"
                      fill="#EDEFF1"
                    />
                  </g>
                  <path
                    d="M87 139L129 192.5L171 139C136.2 123.8 100.5 132.667 87 139Z"
                    fill="#DEE2EB"
                  />
                  <defs>
                    <filter
                      id="filter0_d_1585_10403"
                      x="68"
                      y="21"
                      width="121"
                      height="121"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.358658 0 0 0 0 0.437205 0 0 0 0 0.6317 0 0 0 1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1585_10403"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1585_10403"
                        result="shape"
                      />
                    </filter>
                    <filter
                      id="filter1_d_1585_10403"
                      x="30"
                      y="126"
                      width="198"
                      height="121"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.358658 0 0 0 0 0.437205 0 0 0 0 0.6317 0 0 0 1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1585_10403"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1585_10403"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
              <div
                style={{
                  marginLeft: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <label>{nameUser}</label>
                <p style={{ textAlign: 'center' }}>{callStatus}</p>
                <div className="flex items-center justify-center space-x-2">
                  <RadioButtonCheckedIcon />
                  <p className="text-center">{formatDuration(callDuration)}</p>
                </div>
              </div>
            </div>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleMinimize}
              aria-label="minimize"
              sx={{ position: 'absolute', right: 15, top: 8 }}
            >
              <RemoveIcon />
            </IconButton>
          </div>
          <div>
            {/* <> */}
            <div
              style={{
                display: 'flex',
                gap: '10px',
                marginTop: '5px',
                justifyContent: 'center',
                position: 'absolute',
                backgroundColor: 'red',
                borderRadius: '66px',
                padding: '10px 24px',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                bottom: '10px',
                color: 'white',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              <button
                variant="outlined"
                color="primary"
                onClick={toggleMic}
                fullWidth
                style={{
                  width: '42px',
                  height: '42px',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  // backgroundColor:'rgba(33, 50, 64, 0.8)'
                }}
              >
                {isMicOn ? <MicIcon /> : <MicOffIcon />}
              </button>
              <button
                variant="contained"
                onClick={endCall}
                fullWidth
                style={{
                  width: '42px',
                  height: '42px',
                  borderRadius: '50%',
                  backgroundColor: 'red',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                }}
              >
                <CallEndIcon />
              </button>
            </div>
            {/* </> */}
          </div>
        </div>
      </Draggable>
      <Draggable handle=".draggable-handle">
        <div
          style={{
            position: 'fixed',
            bottom: '20px',
            left: '20px',
            zIndex: isMinimized ? 1000 : -1000,
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <div
            style={{
              position: 'relative',
              width: '150px',
              height: '160px',
              backgroundColor: 'rgba(33, 50, 64, 0.8)',
              borderRadius: '10px',
              overflow: 'hidden',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div style={{}} className="draggable-handle">
              {/* <img
                src={`data:image/png;base64,${imageUser || Avatar}`}
                alt=""
                style={{ width: "100px" }}
              /> */}
              <svg
                width="70"
                height="70"
                viewBox="0 0 259 259"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="129.5" cy="129.5" r="129.5" fill="#6E83B7" />
                <g filter="url(#filter0_d_1585_10403)">
                  <circle cx="128.5" cy="77.5" r="52.5" fill="#EDEFF1" />
                </g>
                <g filter="url(#filter1_d_1585_10403)">
                  <ellipse
                    cx="129"
                    cy="182.5"
                    rx="91"
                    ry="52.5"
                    fill="#EDEFF1"
                  />
                </g>
                <path
                  d="M87 139L129 192.5L171 139C136.2 123.8 100.5 132.667 87 139Z"
                  fill="#DEE2EB"
                />
                <defs>
                  <filter
                    id="filter0_d_1585_10403"
                    x="68"
                    y="21"
                    width="121"
                    height="121"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.358658 0 0 0 0 0.437205 0 0 0 0 0.6317 0 0 0 1 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_1585_10403"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_1585_10403"
                      result="shape"
                    />
                  </filter>
                  <filter
                    id="filter1_d_1585_10403"
                    x="30"
                    y="126"
                    width="198"
                    height="121"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.358658 0 0 0 0 0.437205 0 0 0 0 0.6317 0 0 0 1 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_1585_10403"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_1585_10403"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
            <label
              style={{
                fontSize: '10px',
                color: 'white',
                marginTop: '10px',
                fontWeight: 'bold',
              }}
            >
              {nameUser}
            </label>
            <button
              onClick={handleRestore}
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',

                top: '5px',
                right: '5px',
                position: 'absolute',
                color: '#fff',
                border: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                cursor: 'pointer',
              }}
            >
              <OpenInNewIcon style={{ fontSize: '20px' }} />
            </button>
          </div>
        </div>
      </Draggable>
    </>
  );
};

export default AudioCall;
