import React from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import Draggable from 'react-draggable';
import Avatar from '../../assets/image/Avatar.png';
import CallEndIcon from '@mui/icons-material/CallEnd';
const IncomingCall = ({
  imageUser,
  nameUser,
  isVideoCall,
  callStatus,
  isMicOn,
  closeDialog,
  acceptCall,
  endCall,
  toggleMic,
}) => {
  return (
    <Draggable handle=".draggable-handle">
      <div className="incoming-call">
        {/* <DialogTitle style={{ padding: "10px" }} className="draggable-handle">
          {isVideoCall ? "Incoming Video Call" : "Incoming Call"}
          <IconButton
            edge="end"
            color="inherit"
            onClick={closeDialog}
            aria-label="close"
            sx={{ position: "absolute", right: 15, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle> */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{}} className="draggable-handle">
            {/* <img
                  src={`data:image/png;base64,${imageUser || Avatar}`}
                  alt=""
                  style={{ width: "100px", }}
                /> */}
            <svg
              width="150"
              height="150"
              viewBox="0 0 259 259"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="129.5" cy="129.5" r="129.5" fill="#6E83B7" />
              <g filter="url(#filter0_d_1585_10403)">
                <circle cx="128.5" cy="77.5" r="52.5" fill="#EDEFF1" />
              </g>
              <g filter="url(#filter1_d_1585_10403)">
                <ellipse cx="129" cy="182.5" rx="91" ry="52.5" fill="#EDEFF1" />
              </g>
              <path
                d="M87 139L129 192.5L171 139C136.2 123.8 100.5 132.667 87 139Z"
                fill="#DEE2EB"
              />
              <defs>
                <filter
                  id="filter0_d_1585_10403"
                  x="68"
                  y="21"
                  width="121"
                  height="121"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="4" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.358658 0 0 0 0 0.437205 0 0 0 0 0.6317 0 0 0 1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1585_10403"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1585_10403"
                    result="shape"
                  />
                </filter>
                <filter
                  id="filter1_d_1585_10403"
                  x="30"
                  y="126"
                  width="198"
                  height="121"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="4" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.358658 0 0 0 0 0.437205 0 0 0 0 0.6317 0 0 0 1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1585_10403"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1585_10403"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
          <div style={{ color: 'white', marginTop: '10px', fontSize: '20px' }}>
            <label>{nameUser}</label>
          </div>
          <div style={{ color: 'white', marginTop: '10px', fontSize: '13px' }}>
            {callStatus}
          </div>
        </div>
        <DialogActions>
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '10px',
                marginTop: '10px',
                justifyContent: 'center',
              }}
            >
              <button
                variant="contained"
                color="success"
                onClick={acceptCall}
                fullWidth
                style={{
                  width: '42px',
                  height: '42px',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  backgroundColor: 'green',
                }}
              >
                <CallIcon />
              </button>
              {/* <button
                variant="outlined"
                color="primary"
                onClick={toggleMic}
                fullWidth
                style={{
                  width: "42px",
                  height: "42px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isMicOn ? <MicIcon /> : <MicOffIcon />}
              </button> */}
              <button
                variant="contained"
                color="secondary"
                onClick={endCall}
                fullWidth
                style={{
                  width: '42px',
                  height: '42px',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'red',
                  color: 'white',
                }}
              >
                <CallEndIcon />
              </button>
            </div>
          </div>
        </DialogActions>
      </div>
    </Draggable>
  );
};

export default IncomingCall;
