// components/Maincontent.jsx
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar';

export default function Maincontent({ sidebarToggle, setSidebarToggle }) {
  const handleOfSidebar = (e) => {
    if(e.target.className === 'main-content ') {
      setSidebarToggle(!sidebarToggle)
    }
  }
  return (
    <div className={`main-content ${sidebarToggle ? 'active' : ''}`} onClick={handleOfSidebar}>
      <Navbar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
      <Outlet /> 
    </div>
  );
}
