import React from "react";
import "../../css/Dashboard.css";
import "../../css/ChartReponsive.css";
import SuccessConver from "../../charts/SuccessConver";
import AvgResponse from "../../charts/AvgResponse";
import Overview from "../../charts/Overview";
import Popular from "../../charts/Popular";
import { useTranslation } from "react-i18next";
const Home = () => {
  const { t } = useTranslation();
  return (
    <div className="main-dashboard custom-scrollbar py-10 px-20">
      {/* First row */}
      <div className="flex items-stretch gap-4 mb-6 item-chart-main">
        {/* Overview chart */}
        <div className="chart-overview flex-[1_1_100%]">
          <div className="chart-container">
            <div className="title-chart">
              <h4> {t("overview")}</h4>
            </div>
            <div className="chart-main">
              <Overview />
            </div>
          </div>
        </div>

        {/* Popular chart */}
        <div className="chart-popular flex-[1_1_100%]">
          <div className="chart-container h-full">
            <div className="title-chart">
              <h4> {t("popularTopics")}</h4>
            </div>
            <div className="chart-main h-full flex items-baseline px-10 justify-between !pt-10">
              <Popular />
            </div>
          </div>
        </div>
      </div>

      {/* Second row */}
      <div className="flex items-start gap-4 item-chart-main">
        {/* Successful conversation chart */}
        <div className="chart-success flex-[1_1_100%]">
          <div className="chart-container ">
            <div className="title-chart flex justify-between">
              <h4> {t("successfulConversation")}</h4>
              <span className="sub-title-chart">Last 7 days</span>
            </div>
            <div className="chart-main">
              <SuccessConver />
            </div>
          </div>
        </div>

        {/* Avg response time chart */}
        <div className="chart-avg-response flex-[1_1_100%]">
          <div className="chart-container">
            <div className="title-chart flex justify-between">
            <h4> {t("avgResponseTime")}</h4>
              <span className="sub-title-chart">Last 7 days</span>
            </div>
            <div className="chart-main">
              <AvgResponse />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
