import React from 'react';
import logo from '../../assets/image/Logo-notext-dark.png';
import { useDispatch, useSelector } from 'react-redux';
import { setChatRoomActive } from '../Redux/connectionSlice';
import { changeTabChat } from '../Redux/connectionSlice';
import { useNavigate } from 'react-router-dom';

export default function PushToast(props) {
  const pathName = window.location.pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const heandleOpenRoom = () => {
    if (props.group === 'Createconversation') {
      dispatch(changeTabChat('pending'));
    } else if (props.group === 'Chat') {
      dispatch(changeTabChat('live'));
    }
    dispatch(setChatRoomActive(props.link));
    if (pathName !== '/Chat') {
      navigate('/Chat');
    }
    props.closeToast();
  };

  return (
    <div onClick={() => heandleOpenRoom()} className="flex items-start gap-2">
      <img
        src={props.image || logo}
        alt="icon"
        style={{ marginRight: '8px' }}
      />
      <div>
        <div className="font-bold">{props.title}</div>
        <div className="font-normal text-[14px] opacity-80 mt-[8px]">
          {props.body}
        </div>
      </div>
    </div>
  );
}
