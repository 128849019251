import { env } from "../env"
const commont = {}
export const getParamConfig = (key) => {
    const val = env[`REACT_APP_${key}`]
    try {
        return val
    } catch (error) {
        console.log(error)
    }
}
 
export const setVariable = (key, value) => {
 
    if (value) {
        localStorage.setItem(key, value);
    }
    else
        localStorage.removeItem(key)
}
 
export const getVariable = (key) => {
   const item = localStorage.getItem(key)
    return item;
}
 
export const setVariableTemp = (key, value) => {
 
    if (value) {
        sessionStorage.setItem(key, value);
    }
    else
        sessionStorage.removeItem(key)
}
 
export const getVariableTemp = (key) => {
    return sessionStorage.getItem(key)
}
export const isIdExistingMess = (dataArray, idToCheck) => {
    return dataArray.some(item => {
      const dataKeys = Object.keys(item);
      for (const key of dataKeys) {
        const innerArray = item[key];
        const foundItem = innerArray.find(obj => obj.id === idToCheck);
        if (foundItem) {
          return true;
        }
      }
      return false;
    })
  }
 
export default commont