import React, { useCallback, useState, useEffect, useRef } from 'react';
import { TimeIcon } from '@mui/x-date-pickers';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import createAxiosClient from '../../utils/axiosClient';
import { useDispatch } from 'react-redux';
import { setMediaGallery } from '../Redux/connectionSlice';

export default function ChatItem({
  chatInfo,
  onChatItemClick,
  setTypeChatToggle,
  typeChatToggle,
  index,
}) {
  const dispatch = useDispatch();
  const chatItemRef = useRef(null);
  const [isFirstItemClicked, setIsFirstItemClicked] = useState(false);
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);
  const navigate = useNavigate();
  const axiosClient = createAxiosClient(navigate);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileOrTablet(window.matchMedia('(max-width: 1024px)').matches);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const handleClickItem = useCallback(
    (e) => {
      const Token = localStorage.getItem('authToken');
      localStorage.setItem('nameUser', chatInfo.Name);
      if (!Token) {
        console.error('No token found');
        return;
      }

      if (isMobileOrTablet) {
        if (index === 0 && !isFirstItemClicked) {
          setIsFirstItemClicked(true);
        } else {
          setTypeChatToggle(!typeChatToggle);
        }
      }

      const activeItem = document.querySelector('.chat-item-wraper.active');
      if (activeItem) {
        activeItem.classList.remove('active');
      }
      e.currentTarget.classList.add('active');
      const fetchChatData = async () => {
        try {
          const body = {
            RoomId: chatInfo.RoomId,
          };
          const result = await axiosClient.post('/chatroom/history/info', body);

          onChatItemClick(result); // Gọi hàm với dữ liệu trả về
        } catch (error) {
          // Hiển thị thông báo lỗi
          toast.error(
            'Error fetching chat data: ' + (error.message || 'Unknown error'),
            {
              position: 'top-right',
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            }
          );
        }
      };
      const fetchMediaData = async () => {
        try {
          const body = {
            RoomId: chatInfo.RoomId,
          };
          const result = await axiosClient.post('/chatroom/moreinfo', body);
          result && dispatch(setMediaGallery(result));
        } catch (error) {
          console.error('Error: ', error);
        }
      };

      fetchChatData();
      fetchMediaData();
    },
    [
      onChatItemClick,
      typeChatToggle,
      index,
      isFirstItemClicked,
      isMobileOrTablet,
    ]
  );

  useEffect(() => {
    const activeElements = document.querySelectorAll(
      '.chat-item-wraper.active'
    );
    if (activeElements.length === 0) {
      const firstChatItem = document.querySelector('.chat-item-wraper');
      if (firstChatItem) {
        // setTypeChatToggle(typeChatToggle);
        // firstChatItem.click();
      }
    }
  }, []);
  return (
    <div
      className="chat-item-wraper bg-white dark:bg-black"
      onClick={handleClickItem}
      ref={chatItemRef}
    >
      <div className="chat-item-info">
        <p className="chat-name">{chatInfo.Name}</p>
        <div className="flex items-center justify-between">
          <span className="text-[12px] text-[#858585] font-medium">
            {chatInfo.EndTime}
          </span>
          <span className="text-[12px] text-[#707070] font-medium flex items-center gap-1">
            <TimeIcon className="!w-[15px]" /> {chatInfo.TotalTime}
          </span>
        </div>
      </div>
    </div>
  );
}
