import React, { useState } from 'react';
import ChatLeft from './ChatLeft';
import ChatDetail from './ChatDetail';
import UserInfo from '../ChatApp/UserInfo';

export default function History() {
  const [typeChatToggle, setTypeChatToggle] = useState(true);
  const [selectedChatDetail, setSelectedChatDetail] = useState(null);
  const [toggleUserInfo, setToggleUserInfo] = useState(false);

  const handleChatItemClick = (chatDetailData) => {
    setSelectedChatDetail(chatDetailData);
  };

  return (
    <div id="chat-screen" className="chat-main relative overflow-hidden">
      <ChatLeft
        typeChatToggle={typeChatToggle}
        setTypeChatToggle={setTypeChatToggle}
        onChatItemClick={handleChatItemClick}
      />
      <ChatDetail
        data={selectedChatDetail}
        typeChatToggle={typeChatToggle}
        setTypeChatToggle={setTypeChatToggle}
        toggleUserInfo={toggleUserInfo}
        setToggleUserInfo={setToggleUserInfo}
      />
      <UserInfo
        data={selectedChatDetail}
        toggleUserInfo={toggleUserInfo}
        setToggleUserInfo={setToggleUserInfo}
        isHistory={true}
      />
    </div>
  );
}
