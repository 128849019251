import React, { useState } from "react";
import ChatItem from "./ChatItem";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from "react-i18next";
const LiveChatComponent = ({
  liveChats,
  onChatItemClick,
  sourceTab,
  onTab,
  setTypeChatToggle,
  typeChatToggle,
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(true);
  const [isExpandedRecent, setIsExpandedRecent] = useState(true);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleToggleRecent = () => {
    setIsExpandedRecent(!isExpandedRecent);
  };

  return (
    <div>
      {liveChats && liveChats.DataHoldingChat && liveChats.DataHoldingChat.length !== 0 ? (
        <>
          <button className="block text-[#6A6A73] text-[12px] font-medium leading-6 text-left pl-2 button-arrow" onClick={handleToggle}>
            {isExpanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />} {t("holdingChat")}
          </button>
          {isExpanded && (
            <ul>
              {liveChats.DataHoldingChat.map((chat, index) => (
                <ChatItem
                  key={index}
                  index={index}
                  chatInfo={chat}
                  onChatItemClick={onChatItemClick}
                  sourceTab={sourceTab}
                  onTab={onTab}
                  setTypeChatToggle={setTypeChatToggle}
                  typeChatToggle={typeChatToggle}
                />
              ))}
            </ul>
          )}
        </>
      ) : null}
      {liveChats && liveChats.Data && liveChats.Data.length > 0 ? (
        <>
          <button className="block text-[#6A6A73] text-[12px] font-medium leading-6 text-left pl-2 button-arrow" onClick={handleToggleRecent}>
            {isExpandedRecent ? <ArrowDropDownIcon /> : <ArrowRightIcon />} {t("recent")}
          </button>
          {isExpandedRecent && (
            <ul>
              {
                liveChats.Data.map((chat, index) => (
                  <ChatItem
                    key={index}
                    index={index}
                    chatInfo={chat}
                    onChatItemClick={onChatItemClick}
                    sourceTab={sourceTab}
                    onTab={onTab}
                    setTypeChatToggle={setTypeChatToggle}
                    typeChatToggle={typeChatToggle}
                  />
                ))
              }
            </ul>
          )}
        </>
      ) : (
        null
      )}
      
      {<div className="chat-item-wraper-chat">
        <div className="chat-loading">
          {liveChats && liveChats.Data && liveChats.Data.length === 0 && liveChats.DataHoldingChat && liveChats.DataHoldingChat.length === 0 ? (
            <div className="text-[15px] text-[#656565] font-medium">
              {t("noMessagesAvailable")}
            </div>
          ) : !liveChats || !liveChats.Data || !liveChats.DataHoldingChat ? (
            <span className="loader-chat"></span>
          ) : null}
        </div>
      </div>}
    </div>
  );
};

export default LiveChatComponent;
