import React, { useState, useEffect, useRef } from 'react';
import Avatar from '../../assets/image/Avatar.png';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import MailIcon from '@mui/icons-material/Mail';
import EditIcon from '@mui/icons-material/Edit';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SimplePeer from 'simple-peer';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import VideoCall from './VideoCall';
import IncomingCall from './IncomingCall';
import AudioCall from './AudioCall';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import '../../css/UserInfo.css';
import { useTranslation } from "react-i18next";
const UserInfo = ({
  toggleUserInfo,
  data,
  setToggleUserInfo,
  isHistory,
  setSelectedChatDetail,
  handleChangeName,
}) => {
  const { t } = useTranslation();
  const [showCallDialog, setShowCallDialog] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const [callStatus, setCallStatus] = useState('');
  const [isMicOn, setIsMicOn] = useState(true);
  const [isCameraOn, setIsCameraOn] = useState(true);
  const peerRef = useRef(null);
  const localStreamRef = useRef(null);
  const remoteStreamRef = useRef(null);
  const connection = useSelector((state) => state.connection.connection);
  const fakeMedia = useSelector((state) => state.connection.mediaGallery);

  const [incomingCall, setIncomingCall] = useState(false);
  const [incomingVideoCall, setIncomingVideoCall] = useState(false);
  const [incomingSignal, setIncomingSignal] = useState(null);
  const [showVideoCallDialog, setShowVideoCallDialog] = useState(false);
  const [isSharingScreen, setIsSharingScreen] = useState(false);
  const [callDuration, setCallDuration] = useState(0);

  // media gallery logic start
  const [tabChange, setTabChange] = useState('media');
  const [selectedImage, setSelectedImage] = useState(null);
  const [inGallery, setInGallery] = useState(false);
  const images = require.context('../../assets/image/fileicon', true);
  const [currentRoomId, setCurrentRoomId] = useState(null);
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [newName, setNewName] = useState(
    data?.UserInfo?.NickName || data?.UserInfo?.Name
  );

  const handleEditClick = () => {
    setIsPopupOpen(true); // Show the popup when the edit icon is clicked
  };

  const handleNameChange = (e) => {
    setNewName(e.target.value); // Update name as the user types
  };

  const handleSaveClick = () => {
    // Update the UserInfo.Name in selectedChatDetail
    handleChangeName(data.RoomId, newName);

    setSelectedChatDetail((prevDetail) => ({
      ...prevDetail,
      UserInfo: {
        ...prevDetail.UserInfo,
        NickName: newName,
      },
    }));

    setIsPopupOpen(false); // Close the popup after saving the name
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const filterMedia = (media) => {
    if (!fakeMedia || !fakeMedia.AttachInfo) return [];

    return media.filter((item) => {
      const roleType = item.RoleType === null ? 'user' : item.RoleType;
      const matchesRole = selectedRole ? roleType === selectedRole : true;
      const matchesDate = selectedDate
        ? dayjs(item.DateCreated).isSame(selectedDate, 'day')
        : true;

      return matchesRole && matchesDate;
    });
  };

  const handleTabChange = (tab) => {
    setTabChange(tab);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClosePopup = () => {
    setSelectedImage(null);
  };

  const handleSwitchToGallery = () => {
    if (inGallery === true) {
      setInGallery(false);
      setSelectedRole('');
      setSelectedDate(null);
    } else {
      setInGallery(true);
    }
  };

  const getFileIcon = (fileType) => {
    if (fileType.includes('pdf')) return images('./pdf.png');
    if (fileType.includes('.document')) return images('./docx.png');
    if (fileType.includes('.sheet')) return images('./csv.png');
    return images('./all.png');
  };

  const NoMediaMessage = () => (
    <div className="flex flex-col items-center w-full py-6 text-gray-500">
      <img
        src={images('./nomedia.png')}
        alt="No media"
        className="w-12 h-12 mb-2"
      />
      <p className="text-[13px]">There is no media shared</p>
    </div>
  );

  // media gallery logic end

  const intervalRef = useRef(null);

  const handleToggleUserInfo = () => {
    setToggleUserInfo((prev) => !prev);
  };

  const handleCallClick = () => {
    setShowCallDialog(true);
    setIncomingCall(false);
    startAudioCall();
  };

  useEffect(() => {
    setNewName(data?.UserInfo?.NickName || data?.UserInfo?.Name);
  }, [data]);

  useEffect(() => {
    if (connection) {
      connection.on('ReceiveSignal', (signalString) => {
        const signalData = JSON.parse(signalString);
        const signal = signalData.signal;
        setIncomingSignal(signal);

        const isVideoCall = signalData.isVideoCall;
        const signalType = signalData.signalType;
        if (isVideoCall) {
          if (signalType === 'call') {
            setIncomingVideoCall(true);
            setCurrentRoomId(signalData.RoomId);
          }
          setCallStatus('Incoming video call...');
        } else {
          if (signalType === 'call') {
            setIncomingCall(true);
            setCurrentRoomId(signalData.RoomId);
          }
          setCallStatus('Incoming audio call...');
        }

        if (!peerRef.current) {
          setCallStatus('Incoming call...');
        } else {
          const peerConnection = peerRef.current._pc;
          if (peerConnection.signalingState === 'stable') {
          } else {
            try {
              peerRef.current.signal(signal);
            } catch (error) {
              console.error('Error during signaling:', error);
            }
          }
        }
      });

      connection.on('CallEnded', (RoomId) => {
        closeEndCall();
      });
    }
  }, [connection]);
  const handleIncomingVideoCall = () => {
    acceptVideoCall();
    setIncomingVideoCall(false);
    setShowVideoCallDialog(true); //
  };
  const handleIncomingAudioCall = () => {
    acceptAudioCall();
    setIncomingCall(false);
    setShowCallDialog(true); //
  };
  const initializePeer = async (initiator, signal) => {
    try {
      setCurrentRoomId(data.RoomId);
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: isMicOn,
      });
      localStreamRef.current = stream;

      const peer = new SimplePeer({
        initiator,
        trickle: false,
        stream: initiator ? localStreamRef.current : null,
      });

      peerRef.current = peer;
      peer.on('signal', (signal) => {
        const signalData = {
          signal: signal,
          signalType: 'call',
        };
        const signalString = JSON.stringify(signalData);
        connection.send(
          'SendSignal',
          connection.connectionId,
          signalString,
          data.RoomId
        );
      });

      peer.on('stream', (remoteStream) => {
        remoteStreamRef.current = remoteStream;
        const audio = new Audio();
        audio.srcObject = remoteStream;
        audio.play();
        setCallStatus('Connected');

        setCallDuration(0);
        intervalRef.current = setInterval(() => {
          setCallDuration((prevDuration) => prevDuration + 1);
        }, 1000);
      });

      if (!initiator) {
        peer.signal(signal);
      }
    } catch (error) {
      toast.error('Error accessing microphone. Please check your permissions.');
    }
  };
  const startAudioCall = async () => {
    setIsCalling(true);
    setCallStatus('Calling...');
    // await connection.send("JoinCall", data.RoomId);
    initializePeer(true);
  };

  const endAudioCall = () => {
    if (localStreamRef.current) {
      localStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    if (peerRef.current) {
      peerRef.current.destroy();
    }
    if (connection) {
      connection.send('EndCall', currentRoomId);
    }
    setCallStatus('Call Ended');
    setShowCallDialog(false);
    setShowVideoCallDialog(false);
    setIncomingCall(false);
    setIncomingVideoCall(false);
    setIsMicOn(true);
    setIsCameraOn(true);
    setIsSharingScreen(false);

    clearInterval(intervalRef.current);
    setCallDuration(0);
  };
  const closeEndCall = () => {
    setIsSharingScreen(false);
    setIsCalling(false);
    if (localStreamRef.current) {
      localStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    if (peerRef.current) {
      peerRef.current.destroy();
    }
    setIsMicOn(true); //
    setIsCameraOn(true);
    setShowCallDialog(false);
    setShowVideoCallDialog(false);
    setIncomingCall(false);
    setIncomingVideoCall(false);
    clearInterval(intervalRef.current);
    setCallDuration(0);
  };
  const toggleMic = () => {
    if (localStreamRef.current) {
      localStreamRef.current.getAudioTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
      setIsMicOn((prev) => !prev);
    }
  };
  const toggleCamera = () => {
    if (localStreamRef.current) {
      localStreamRef.current.getVideoTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
      setIsCameraOn((prev) => !prev);
    }
  };
  const closeIncomingCall = () => {
    setIncomingCall(false);
  };
  const closeIncomingVideoCall = () => {
    setIncomingVideoCall(false);
  };
  const closeVideoCall = () => {
    setShowVideoCallDialog(false);
    endAudioCall();
  };
  const acceptAudioCall = () => {
    navigator.mediaDevices.getUserMedia({ audio: isMicOn }).then((stream) => {
      localStreamRef.current = stream;

      const peer = new SimplePeer({
        initiator: false,
        trickle: false,
        stream: stream,
        config: {
          iceServers: [
            { urls: 'stun:stun.l.google.com:19302' },
            {
              urls: 'turn:your.turn.server:3478',
              username: 'user',
              credential: 'pass',
            },
          ],
        },
      });

      peerRef.current = peer;

      peer.on('stream', (remoteStream) => {
        remoteStreamRef.current = remoteStream;
        const audio = new Audio();
        audio.srcObject = remoteStream;
        audio.play();
        setCallStatus('Connected');

        setCallDuration(0);
        intervalRef.current = setInterval(() => {
          setCallDuration((prevDuration) => prevDuration + 1);
        }, 1000);
      });
      if (incomingSignal) {
        peer.signal(incomingSignal);
      }

      peer.on('signal', (signal) => {
        const signalData = {
          signal: signal,
          signalType: 'answer',
        };
        const signalString = JSON.stringify(signalData);
        connection.send(
          'SendSignal',
          connection.connectionId,
          signalString,
          currentRoomId
        );
      });
    });
  };
  const acceptVideoCall = () => {
    setIncomingCall(false);

    navigator.mediaDevices
      .getUserMedia({ audio: isMicOn, video: true })
      .then((stream) => {
        localStreamRef.current = stream;
        const localVideo = document.getElementById('localVideo');
        if (localVideo) {
          localVideo.srcObject = stream;
          localVideo.play();
        }

        const peer = new SimplePeer({
          initiator: false,
          trickle: false,
          stream: stream,
          config: {
            iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
          },
        });

        peerRef.current = peer;

        peer.on('stream', (remoteStream) => {
          remoteStreamRef.current = remoteStream;
          const remoteVideo = document.getElementById('remoteVideo');
          if (remoteVideo) {
            remoteVideo.srcObject = remoteStream;
            remoteVideo.play();
          }
          const remoteVideoMini = document.getElementById('remoteVideoMini');
          if (remoteVideoMini) {
            remoteVideoMini.srcObject = remoteStream;
            remoteVideoMini.play();
          }
          setCallStatus('Connected');

          setCallDuration(0);
          intervalRef.current = setInterval(() => {
            setCallDuration((prevDuration) => prevDuration + 1);
          }, 1000);
        });

        if (incomingSignal) {
          peer.signal(incomingSignal);
        }

        peer.on('signal', (signal) => {
          const signalData = {
            signal: signal,
            isVideoCall: true,
            signalType: 'answer',
          };
          const signalString = JSON.stringify(signalData);
          connection.send(
            'SendSignal',
            connection.connectionId,
            signalString,
            currentRoomId
          );
        });
      })
      .catch((error) => {
        console.error('Error accessing media devices:', error);
        toast.error('Failed to access media devices.');
      });
  };
  const handleVideoCallClick = () => {
    setShowVideoCallDialog(true);
    setIncomingCall(false);
    startVideoCall();
  };
  const startVideoCall = async () => {
    setCallStatus('Calling...');
    // await connection.send("JoinCall", data.RoomId);
    setCurrentRoomId(data.RoomId);
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: isMicOn })
      .then((stream) => {
        localStreamRef.current = stream;

        const localVideo = document.getElementById('localVideo');
        if (localVideo) {
          localVideo.srcObject = stream;
          localVideo.play();
        }

        const peer = new SimplePeer({
          initiator: true,
          trickle: false,
          stream: stream,
          config: {
            iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
          },
        });

        peerRef.current = peer;
        peer.on('signal', (signal) => {
          const signalData = {
            signal: signal,
            isVideoCall: true,
            signalType: 'call',
          };
          const signalString = JSON.stringify(signalData);
          connection.send(
            'SendSignal',
            connection.connectionId,
            signalString,
            data.RoomId
          );
        });

        peer.on('stream', (remoteStream) => {
          remoteStreamRef.current = remoteStream;
          const remoteVideo = document.getElementById('remoteVideo');
          if (remoteVideo) {
            remoteVideo.srcObject = remoteStream;
            remoteVideo.play();
          }
          const remoteVideoMini = document.getElementById('remoteVideoMini');
          if (remoteVideoMini) {
            remoteVideoMini.srcObject = remoteStream;
            remoteVideoMini.play();
          }
          setCallStatus('Connected');

          setCallDuration(0);
          intervalRef.current = setInterval(() => {
            setCallDuration((prevDuration) => prevDuration + 1);
          }, 1000);
        });
      })
      .catch((error) => {
        console.error('Error accessing camera or microphone:', error);
        toast.error(
          'Error accessing camera or microphone. Please check your permissions.'
        );
      });
  };
  const toggleShareScreen = () => {
    if (isSharingScreen) {
      stopShareScreen();
    } else {
      handleShareScreen();
    }
    setIsSharingScreen(!isSharingScreen);
  };
  const handleShareScreen = async () => {
    try {
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });

      // Kiểm tra xem screenStream có video track không
      if (screenStream.getVideoTracks().length === 0) {
        toast.error('No screen selected for sharing.');
        return; // Dừng lại nếu không có video track
      }

      // Dừng stream cũ nếu có
      // if (localStreamRef.current) {
      //   localStreamRef.current.getTracks().forEach((track) => track.stop());
      // }

      localStreamRef.current = screenStream;

      const localVideo = document.getElementById('localVideo');
      if (localVideo) {
        localVideo.srcObject = screenStream;
        localVideo.play();
      }
      screenStream.getVideoTracks()[0].onended = stopShareScreen;
      // Thay thế track video trong peer stream
      peerRef.current.replaceTrack(
        peerRef.current.streams[0].getVideoTracks()[0],
        screenStream.getVideoTracks()[0],
        peerRef.current.streams[0]
      );

      toast.success('Sharing screen!');
    } catch (error) {
      console.error('Error sharing screen:', error);
      toast.error('Failed to share screen.');
    }
  };

  const stopShareScreen = async () => {
    try {
      const tracks = localStreamRef.current.getTracks();
      tracks.forEach((track) => track.stop());

      const videoStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: isMicOn,
      });

      localStreamRef.current = videoStream;
      const localVideo = document.getElementById('localVideo');
      if (localVideo) {
        localVideo.srcObject = videoStream;
        localVideo.play();
      }

      peerRef.current.replaceTrack(
        peerRef.current.streams[0].getVideoTracks()[0],
        videoStream.getVideoTracks()[0],
        peerRef.current.streams[0]
      );
      setIsSharingScreen(false);
      toast.success('Switched back to video stream.');
    } catch (error) {
      console.error('Error switching back to video stream:', error);
      toast.error('Failed to switch back to video stream.');
    }
  };

  return (
    <>
      <div
        className={`overlay ${toggleUserInfo ? 'active' : ''}`}
        onClick={handleToggleUserInfo}
      ></div>

      <div
        className={`user-wrapper overflow-y-auto no-scrollbar flex-col ${
          toggleUserInfo ? 'active' : ''
        }`}
      >
        <div
          className={`flex justify-between w-full px-5 ${
            inGallery
              ? 'bg-white pt-[26px]'
              : 'bg-gradient-to-r from-[#EBF5FD] to-[#EBFDF5] pt-[26px] pb-[58px]'
          } `}
        >
          {inGallery ? (
            <div className="flex items-center">
              <ArrowBackIcon
                className="mr-2 cursor-pointer"
                onClick={handleSwitchToGallery}
              />
              <h3>{t("gallery")}</h3>
            </div>
          ) : (
            <h3>{t("profile")}</h3>
          )}
          <CloseIcon
            className="close-user-info cursor-pointer"
            onClick={handleToggleUserInfo}
          />
        </div>

        <div className={`user-info ${inGallery ? '!hidden' : ''}`}>
          <img
            className="user-avatar"
            src={`${data?.UserInfo?.Image || Avatar}`}
            alt="User Avatar"
          />

          <div className="user-name flex justify-center items-center w-full">
            <span className="max-w-[70%] break-all">
              {data?.UserInfo?.NickName || data?.UserInfo?.Name}
            </span>
            <div
              className="cursor-pointer ml-2 bg-[#F0F5FE] rounded-full w-[24px] h-[24px] flex justify-center items-center"
              onClick={handleEditClick}
            >
              <EditIcon style={{ fontSize: '14px' }} />
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <span className="text-[#6A6A73] text-xs font-normal">
              {fakeMedia?.RoomType && fakeMedia.RoomType !== null
                ? `From: ${fakeMedia?.RoomType}`
                : 'From: Digital banking'}
            </span>
          </div>
        </div>

        {isPopupOpen && (
          <div className="fixed inset-0 bg-[#131313] bg-opacity-70 flex justify-center items-center z-50">
            <div className="bg-white rounded-md shadow-lg w-[400px]">
              <label className="block text-sm font-medium mb-2 bg-[#F4F5F6] rounded-t-md px-4 py-3">
                {t("changeName")}
              </label>
              <img
                className="rounded-full w-16 h-16 mx-auto"
                src={`${data?.UserInfo?.Image || Avatar}`}
                alt="User Avatar"
              />
              <div className="p-4">
                <input
                  type="text"
                  value={newName}
                  onChange={handleNameChange}
                  maxLength={30}
                  minLength={1}
                  className="w-full p-2 border border-gray-300 rounded-md mb-2"
                />
                {newName?.length > 29 && (
                  <p className="text-red-500 text-sm mb-2">
                    Name cannot exceed 30 characters
                  </p>
                )}
                {newName?.length < 1 && (
                  <p className="text-red-500 text-sm mb-2">
                    Name cannot be empty
                  </p>
                )}
              </div>

              <div className="flex justify-end gap-3 changename-btn-wrapper p-4">
                <button
                  onClick={() => setIsPopupOpen(false)}
                  className="bg-white text-[#475467] border border-[#D0D5DD] px-5 py-2 rounded-md hover:bg-[#F9FAFB]"
                >
                  {t("cancel")}
                </button>
                <button
                  onClick={handleSaveClick}
                  disabled={newName?.length > 29 || newName?.length < 1}
                  className={`px-5 py-2 rounded-md ${
                    newName?.length > 29 || newName?.length < 1
                      ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                      : 'bg-blue-500 text-white hover:bg-blue-600'
                  }`}
                >
                  Save name
                </button>
              </div>
            </div>
          </div>
        )}

        {!isHistory && (
          <div
            className={`flex items-center gap-2 mt-4 call-wrapper ${
              inGallery ? 'call-wrapper-gallery' : ''
            }`}
          >
            <PhoneForwardedIcon
              onClick={handleCallClick}
              className="cursor-pointer"
              style={{ color: '#6A6A73' }}
            />
            {/* <MailIcon className="cursor-pointer" /> */}
            <VideoCallIcon
              onClick={handleVideoCallClick}
              className="cursor-pointer"
              style={{ color: '#6A6A73', fontSize: '26px' }}
            />
          </div>
        )}

        <div
          className={`${inGallery ? 'hidden' : 'py-7 w-full px-4 bg-white'}`}
        >
          <div className="mb-4 flex items-center justify-between">
            <div className="text-[#6a6a73] text-sm font-medium mr-2 w-5/12">
              {t("email")}
            </div>
            <div className="text-[#1e1e1e] text-sm font-medium w-6/12 break-all">
              {data?.UserInfo?.Email || '-'}
            </div>
          </div>

          <div className="mb-4 flex items-center justify-between">
            <div className="text-[#6a6a73] text-sm font-medium mr-2 w-5/12">
            {t("phoneNumber")}
            </div>
            <div className="text-[#1e1e1e] text-sm font-medium w-6/12 break-all">
              {data?.UserInfo?.Phone || '-'}
            </div>
          </div>

          <div className="mb-4 flex items-center justify-between">
            <div className="text-[#6a6a73] text-sm font-medium mr-2 w-5/12">
              {t("address")}
            </div>
            <div className="text-[#1e1e1e] text-sm font-medium w-6/12 break-all">
              {data?.UserInfo?.Address || '-'}
            </div>
          </div>

          <div className="mb-4 flex items-center justify-between">
            <div className="text-[#6a6a73] text-sm font-medium mr-2 w-5/12">
            {t("licenseType")}
            </div>
            <div className="text-[#1e1e1e] text-sm font-medium w-6/12 break-all">
              {data?.UserInfo?.LicenseType || '-'}
            </div>
          </div>

          <div className="mb-4 flex items-center justify-between">
            <div className="text-[#6a6a73] text-sm font-medium mr-2 w-5/12">
            {t("licenseNo")}
            </div>
            <div className="text-[#1e1e1e] text-sm font-medium w-6/12 break-all">
              {data?.UserInfo?.LicenseNo || '-'}
            </div>
          </div>
        </div>

        {/* media gallery start */}
        <div
          className={`${
            inGallery ? '' : 'mt-2'
          } pt-4 w-full px-4 bg-white grow`}
        >
          <h3 className={`${inGallery ? 'hidden' : ''}`}> {t("gallery")}</h3>

          <div
            className={`${
              inGallery ? '' : 'hidden'
            } flex items-center gap-2 py-3`}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <FormControl size="small" sx={{ width: '200px' }}>
                <InputLabel id="role-select-label">{t("bySender")}</InputLabel>
                <Select
                  labelId="role-select-label"
                  value={selectedRole}
                  label="By Sender"
                  onChange={handleRoleChange}
                  // displayEmpty
                  endAdornment={
                    selectedRole && (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          disableRipple
                          disableFocusRipple
                          onClick={() => {
                            handleRoleChange({ target: { value: '' } });
                            document.activeElement.blur(); // Unfocus the Select component
                          }}
                        >
                          <CloseIcon
                            style={{ fontSize: '16px', marginRight: '5px' }}
                          />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                >
                  <MenuItem value="user">{t("byUser")}</MenuItem>
                  <MenuItem value="teller">{t("byTeller")}</MenuItem>
                </Select>
              </FormControl>

              <DatePicker
                label="By time"
                inputFormat="DD/MM/YYYY HH:mm:ss"
                value={selectedDate}
                onChange={handleDateChange}
                slotProps={{
                  textField: {
                    size: 'small',
                    sx: {
                      width: '200px',
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </div>

          <div className="wraper-button-tab">
            <button
              onClick={() => handleTabChange('media')}
              className={`tab ${tabChange === 'media' ? 'active' : ''}`}
            >
              {t("media")}
            </button>
            <button
              onClick={() => handleTabChange('files')}
              className={`tab ${tabChange === 'files' ? 'active' : ''}`}
            >
              {t("files")}
            </button>
            <button
              onClick={() => handleTabChange('record')}
              className={`tab ${tabChange === 'record' ? 'active' : ''}`}
            >
               {t("record")}
            </button>
          </div>

          {tabChange === 'media' && (
            <div className="flex flex-col gap-4">
              {inGallery ? (
                filterMedia(fakeMedia.AttachInfo?.Media).length > 0 ? (
                  Object.entries(
                    filterMedia(fakeMedia.AttachInfo?.Media).reduce(
                      (grouped, image) => {
                        const date = new Date(
                          image.DateCreated
                        ).toLocaleDateString('en-US', {
                          weekday: 'long',
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        });
                        (grouped[date] ||= []).push(image);
                        return grouped;
                      },
                      {}
                    )
                  ).map(([date, images]) => (
                    <div key={date}>
                      <h3 className="text-sm font-medium font-inter leading-6 text-left text-gray-600 py-2">
                        {date}
                      </h3>
                      <div className="flex flex-wrap">
                        {images.map((image) => (
                          <div
                            key={image.MediaId}
                            className="w-1/3 aspect-square p-2"
                          >
                            {image.Type.includes('video') ? (
                              <video
                                src={image.Url}
                                // controls
                                autoPlay
                                onClick={() => handleImageClick(image)}
                                className="w-full h-full object-cover rounded-[4px] border-2 border-gray-300 cursor-pointer"
                              />
                            ) : (
                              <img
                                src={image.Url}
                                alt={image.Name}
                                onClick={() => handleImageClick(image)}
                                className="w-full h-full object-cover rounded-[4px] border-2 border-gray-300 cursor-pointer"
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <NoMediaMessage />
                )
              ) : filterMedia(fakeMedia.AttachInfo?.Media).length > 0 ? (
                <div className="flex flex-wrap">
                  {filterMedia(fakeMedia.AttachInfo?.Media)
                    .slice(0, 9)
                    .map((image) => (
                      <div
                        key={image.MediaId}
                        className="w-1/3 aspect-square p-2"
                      >
                        {image.Type.includes('video') ? (
                          <video
                            src={image.Url}
                            // controls
                            autoPlay
                            onClick={() => handleImageClick(image)}
                            className="w-full h-full object-cover rounded-[4px] border-2 border-gray-300 cursor-pointer"
                          />
                        ) : (
                          <img
                            src={image.Url}
                            alt={image.Name}
                            onClick={() => handleImageClick(image)}
                            className="w-full h-full object-cover rounded-[4px] border-2 border-gray-300 cursor-pointer"
                          />
                        )}
                      </div>
                    ))}
                </div>
              ) : (
                <NoMediaMessage />
              )}
            </div>
          )}

          {tabChange === 'files' && (
            <div className="flex flex-col gap-4">
              {inGallery ? (
                filterMedia(fakeMedia.AttachInfo?.Files).length > 0 ? (
                  Object.entries(
                    filterMedia(fakeMedia.AttachInfo.Files).reduce(
                      (grouped, file) => {
                        const dateCreated = new Date(file.DateCreated);
                        const dateStr = dateCreated.toLocaleDateString(
                          'en-US',
                          {
                            weekday: 'long',
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                          }
                        );
                        (grouped[dateStr] ||= []).push(file);
                        return grouped;
                      },
                      {}
                    )
                  ).map(([date, files]) => (
                    <div key={date}>
                      <h3 className="text-sm font-medium font-inter leading-6 text-left text-gray-600 py-2">
                        {date}
                      </h3>
                      <div className="flex flex-col mt-1">
                        {files.map((file) => (
                          <a
                            key={file.MediaId}
                            href={file.Url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center mb-2 file-link text-[#313131] text-sm font-medium font-inter leading-6 text-left text-gray-600"
                          >
                            <img
                              src={getFileIcon(file.Type)}
                              alt={`${file.Type} icon`}
                              className="w-10 h-10 mr-3"
                            />
                            <span>{file.Name}</span>
                          </a>
                        ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <NoMediaMessage />
                )
              ) : (
                <div className="flex flex-col">
                  {filterMedia(fakeMedia.AttachInfo?.Files)
                    .slice(0, 3)
                    .map((file) => (
                      <a
                        key={file.MediaId}
                        href={file.Url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center mb-2 file-link text-[#313131] text-sm font-medium font-inter leading-6 text-left text-gray-600"
                      >
                        <img
                          src={getFileIcon(file.Type)}
                          alt={`${file.Type} icon`}
                          className="w-10 h-10 mr-3"
                        />
                        <span>{file.Name}</span>
                      </a>
                    ))}
                  {fakeMedia.AttachInfo?.Files.length === 0 && (
                    <NoMediaMessage />
                  )}
                </div>
              )}
            </div>
          )}

          {tabChange === 'record' && (
            <div className="flex flex-col gap-4">
              {inGallery ? (
                filterMedia(fakeMedia.AttachInfo?.Records).length > 0 ? (
                  Object.entries(
                    filterMedia(fakeMedia.AttachInfo.Records).reduce(
                      (grouped, record) => {
                        const dateCreated = new Date(record.DateCreated);
                        const dateStr = dateCreated.toLocaleDateString(
                          'en-US',
                          {
                            weekday: 'long',
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                          }
                        );
                        (grouped[dateStr] ||= []).push(record);
                        return grouped;
                      },
                      {}
                    )
                  ).map(([date, records]) => (
                    <div key={date}>
                      <h3 className="text-sm font-medium font-inter leading-6 text-left text-gray-600 py-2">
                        {date}
                      </h3>
                      <div className="flex flex-wrap">
                        {records.map((record) => (
                          <div key={record.MediaId} className="p-2">
                            <video
                              src={record.Url}
                              controls
                              onClick={() => handleImageClick(record)}
                              className="w-full h-full object-cover rounded-[4px] border-2 border-gray-300 cursor-pointer"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <NoMediaMessage />
                )
              ) : (
                <div className="flex flex-wrap">
                  {filterMedia(fakeMedia.AttachInfo?.Records)
                    .slice(0, 3)
                    .map((record) => (
                      <div key={record.MediaId} className="p-2">
                        <video
                          src={record.Url}
                          controls
                          onClick={() => handleImageClick(record)}
                          className="w-full h-full object-cover rounded-[4px] border-2 border-gray-300 cursor-pointer"
                        />
                      </div>
                    ))}
                  {fakeMedia.AttachInfo?.Records.length === 0 && (
                    <NoMediaMessage />
                  )}
                </div>
              )}
            </div>
          )}

          {((tabChange === 'files' && fakeMedia.AttachInfo?.FilesCount > 0) ||
            (tabChange === 'media' && fakeMedia.AttachInfo?.MediaCount > 0) ||
            (tabChange === 'record' &&
              fakeMedia.AttachInfo?.RecordsCount > 0)) && (
            <button
              className={`${
                inGallery
                  ? 'hidden'
                  : 'bg-[#EBF6FD] w-full rounded-[6px] my-4 py-2 text-[14px] hover:bg-[#cce7f9] transition-all duration-300'
              }`}
              onClick={handleSwitchToGallery}
            >
              View All
            </button>
          )}
        </div>
        {/* media gallery end */}

        {/* image popup */}
        {selectedImage && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="relative bg-white p-6 rounded-lg max-w-[70vw]">
              <button
                onClick={handleClosePopup}
                className="absolute top-1 right-2 text-gray-600 hover:text-gray-800"
              >
                ✕
              </button>
              {selectedImage?.Type.includes('video') ? (
                <video
                  src={selectedImage.Url}
                  controls
                  className="max-w-full max-h-[80vh] object-cover rounded-[4px] border-2 border-gray-300 cursor-pointer"
                />
              ) : (
                <img
                  src={selectedImage.Url}
                  alt={selectedImage.Name}
                  className="max-w-full max-h-[80vh]"
                />
              )}
            </div>
          </div>
        )}
      </div>

      {incomingCall && (
        <IncomingCall
          imageUser={data?.UserInfo?.Image}
          nameUser={data?.UserInfo?.Name}
          isVideoCall={false}
          callStatus={callStatus}
          isMicOn={isMicOn}
          closeDialog={closeIncomingCall}
          acceptCall={handleIncomingAudioCall}
          endCall={endAudioCall}
          toggleMic={toggleMic}
          callDuration={callDuration}
        />
      )}
      {showCallDialog && (
        <AudioCall
          imageUser={data?.UserInfo?.Image}
          nameUser={data?.UserInfo?.Name}
          callStatus={callStatus}
          isCalling={isCalling}
          isMicOn={isMicOn}
          endCall={endAudioCall}
          toggleMic={toggleMic}
          callDuration={callDuration}
        />
      )}
      {incomingVideoCall && (
        <IncomingCall
          imageUser={data?.UserInfo?.Image}
          nameUser={data?.UserInfo?.Name}
          isVideoCall={true}
          callStatus={callStatus}
          isMicOn={isMicOn}
          closeDialog={closeIncomingVideoCall}
          acceptCall={handleIncomingVideoCall}
          endCall={endAudioCall}
          toggleMic={toggleMic}
          callDuration={callDuration}
        />
      )}
      <VideoCall
        imageUser={data?.UserInfo?.Image}
        nameUser={data?.UserInfo?.Name}
        showVideoCallDialog={showVideoCallDialog}
        endAudioCall={endAudioCall}
        callStatus={callStatus}
        toggleMic={toggleMic}
        isMicOn={isMicOn}
        toggleCamera={toggleCamera}
        isCameraOn={isCameraOn}
        toggleShareScreen={toggleShareScreen}
        isSharingScreen={isSharingScreen}
        callDuration={callDuration}
      />
    </>
  );
};

export default UserInfo;
