import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

function SearchChat({ value, onChange, setTypeChatToggle, typeChatToggle }) {
    const handleToggleChat = () => {
        setTypeChatToggle(!typeChatToggle);
    };

    return (
        <div className='chat-search-wraper flex items-center justify-between'>
            <input 
                type="text" 
                placeholder="Search here" 
                value={value}
                onChange={(e) => onChange(e.target.value)} 
                className='search-chat'
            />
            <div className='close-list-chat cursor-pointer' onClick={handleToggleChat}>
                <CloseIcon />
            </div>
        </div>
    );
}

export default SearchChat;
