import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import createAxiosClient from '../utils/axiosClient';

function Overview() {
  const navigate = useNavigate();
  const axiosClient = createAxiosClient(navigate);
  const [overView, setOverView] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const body = {
          LastDay: '7',
          Type: 'overview',
        };
        const data = await axiosClient.post('/dashboard', body);

        if (data) {
          setOverView(data.OverView);
        }
      } catch (error) {
        // toast.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-wrap justify-between gap-y-4">
      {overView.length > 0 &&
        overView.map((item) => (
          <div
            key={item.Name}
            className={`panel-dashboard-item 
                        ${
                          item.Name === 'Total interactions'
                            ? 'total-interactions'
                            : ''
                        } 
                        ${
                          item.Name === 'Successful conversation'
                            ? 'successfull-conversation'
                            : ''
                        } 
                        ${item.Name === 'Avg messages/chat' ? 'avg-mess' : ''} 
                        ${item.Name === 'AVG time/chat' ? 'avg-time' : ''}`}
          >
            <div className="title-panel-dash">{item.Name}</div>
            <div className="panel-dash-content">
              <div className="panel-dash-total">{item.Value}</div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default Overview;
