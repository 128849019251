import React, { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  Button,
  Typography,
  Avatar,
  FormLabel,
  Box,
} from '@mui/material';
import AvatarMenu from '../../assets/image/Profile.svg';
import { useNavigate } from 'react-router-dom';
import createAxiosClient from '../../utils/axiosClient';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setUpdateFullName } from '../Redux/connectionSlice';
import '../../css/Profile.css';
import { useTranslation } from "react-i18next";
const Profile = () => {
  const { t } = useTranslation();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [formattedBirthday, setFormattedBirthday] = useState('');
  const [loginType, setLoginType] = useState('User');
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const axiosClient = createAxiosClient(navigate);
  const dispatch = useDispatch();
  const fetchUserInfo = async () => {
    const userId = localStorage.getItem('UserId');
    const body = { UserId: userId };
    const result = await axiosClient.post('/user/detail', body);

    if (result) {
      setFullName(result.FullName || '');
      setEmail(result.Email || '');
      setPhoneNo(result.PhoneNo || '');
      setLoginType(result.LoginType || 'Admin');

      localStorage.setItem('nameTeller', result.FullName);

      if (result.Birthday) {
        try {
          const birthday = new Date(result.Birthday);
          if (!isNaN(birthday.getTime())) {
            const day = String(birthday.getDate()).padStart(2, '0');
            const month = String(birthday.getMonth() + 1).padStart(2, '0'); // Tháng 0-indexed
            const year = birthday.getFullYear();
            setFormattedBirthday(`${day}-${month}-${year}`);
          }
        } catch (error) {
          console.error('Invalid Birthday value:', error);
          setFormattedBirthday('');
        }
      }
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    try {
      const [day, month, year] = formattedBirthday.split('-');
      const birthday = new Date(`${year}-${month}-${day}T00:00:00Z`);

      const body = {
        UserId: localStorage.getItem('UserId'),
        FullName: fullName,
        Email: email,
        Birthday: birthday,
        PhoneNo: phoneNo,
      };

      const response = await axiosClient.post('/user/update', body);
      setIsEditing(false);

      if (response.ErrorCode) {
        // localStorage.setItem("FullName", fullName);
        dispatch(setUpdateFullName(true));
        fetchUserInfo();
        toast.success(response.ErrorDesc);
      } else {
        toast.error(response.ErrorDesc);
      }
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    fetchUserInfo();
  };

  return (
    <div className="profile-container">
      <div style={{ width: '80%', marginTop: '5%' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={0}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                borderRadius: '10px',
              }}
            >
              <Box
                bgcolor="rgba(239, 241, 244, 1)"
                width="100%"
                height="10%"
                padding={2}
                display="flex"
                alignItems="center"
                sx={{
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                }}
              >
                <Typography
                  gutterBottom
                  sx={{ color: 'rgba(132,132,132,1)', marginBottom: '0px' }}
                >
                   {t("profilePicture")}
                </Typography>
              </Box>
              <Avatar
                alt="User Avatar"
                src={AvatarMenu}
                sx={{ width: 150, height: 150, marginTop: 5 }}
              />

              {isEditing ? (
                <Button
                  variant="outlined"
                  component="label"
                  style={{ marginTop: 40, textTransform: 'none' }}
                >
                 {t("uploadNewImage")}
                  <input
                    type="file"
                    hidden
                    onChange={(e) => {
                      const file = e.target.files[0];
                    }}
                  />
                </Button>
              ) : (
                <>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    style={{ marginTop: 10, fontWeight: 'bold' }}
                  >
                    {fullName || 'DigitTechs Account'}
                  </Typography>
                </>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper
              elevation={0}
              style={{ height: '100%', borderRadius: '10px' }}
            >
              <Box
                bgcolor="rgba(239, 241, 244, 1)"
                width="100%"
                height="10%"
                padding={2}
                display="flex"
                alignItems="center"
                sx={{
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                }}
              >
                <Typography
                  gutterBottom
                  sx={{ color: 'rgba(132,132,132,1)', marginBottom: '0px' }}
                >
                   {t("accountDetail")}
                </Typography>
              </Box>
              <form>
                <Grid container spacing={2} style={{ padding: '20px' }}>
                  <Grid item xs={12} sm={6}>
                    <FormLabel sx={{ color: '#000' }}>{t("fullName")}</FormLabel>
                    <input
                      type="text"
                      value={fullName}
                      disabled={!isEditing}
                      onChange={(e) => setFullName(e.target.value)}
                      style={{
                        borderRadius: '5px',
                        width: '100%',
                        backgroundColor: isEditing
                          ? 'white'
                          : 'rgba(245, 245, 245, 1)',
                        border: '1px solid rgba(232, 233, 236, 1)',
                        padding: '10px',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormLabel sx={{ color: '#000' }}>{t("email")}</FormLabel>
                    <input
                      type="email"
                      value={email}
                      disabled={!isEditing}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{
                        borderRadius: '5px',
                        width: '100%',
                        backgroundColor: isEditing
                          ? 'white'
                          : 'rgba(245, 245, 245, 1)',
                        border: '1px solid rgba(232, 233, 236, 1)',
                        padding: '10px',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormLabel sx={{ color: '#000' }}>{t("phoneNumber")}</FormLabel>
                    <input
                      type="tel"
                      value={phoneNo}
                      disabled={!isEditing}
                      onChange={(e) => setPhoneNo(e.target.value)}
                      style={{
                        borderRadius: '5px',
                        width: '100%',
                        backgroundColor: isEditing
                          ? 'white'
                          : 'rgba(245, 245, 245, 1)',
                        border: '1px solid rgba(232, 233, 236, 1)',
                        padding: '10px',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormLabel sx={{ color: '#000' }}>{t("birthday")}</FormLabel>
                    <input
                      type="text"
                      value={formattedBirthday || ''}
                      disabled={!isEditing}
                      onChange={(e) => setFormattedBirthday(e.target.value)}
                      placeholder="dd-mm-yyyy"
                      style={{
                        borderRadius: '5px',
                        width: '100%',
                        backgroundColor: isEditing
                          ? 'white'
                          : 'rgba(245, 245, 245, 1)',
                        border: '1px solid rgba(232, 233, 236, 1)',
                        padding: '10px',
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <FormLabel sx={{ color: '#000' }}>Role</FormLabel>
                    <input
                      type="text"
                      value={loginType}
                      disabled={true}
                      style={{
                        borderRadius: '5px',
                        width: '100%',
                        backgroundColor: isEditing
                          ? 'white'
                          : 'rgba(245, 245, 245, 1)',
                        border: '1px solid rgba(232, 233, 236, 1)',
                        padding: '10px',
                      }}
                    />
                  </Grid> */}
                </Grid>
                <Box display="flex" justifyContent="flex-end" padding={2}></Box>
              </form>
            </Paper>
          </Grid>
        </Grid>
        {!isEditing ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleEditClick}
            style={{
              marginTop: 20,
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block',
              textTransform: 'none',
              backgroundColor: 'rgba(100, 149, 245, 1)',
            }}
          >
             {t("editInformation")}
          </Button>
        ) : (
          <div
            style={{
              marginTop: 20,
              display: 'flex',
              justifyContent: 'center',
              gap: '10px',
            }}
          >
            <Button
              variant="contained"
              color="inherit"
              onClick={handleCancelClick}
              style={{
                textTransform: 'none',
                boxShadow: 'none',
                border: '1px solid black',
              }}
            >
              {t("cancel")}
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveClick}
              style={{
                textTransform: 'none',
                backgroundColor: 'rgba(100, 149, 245, 1)',
              }}
            >
              Save changes
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
