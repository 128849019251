import React from 'react';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

export default function PushItem({
  title,
  body,
  time,
  date,
  isread,
  id,
  image,
  readPush,
}) {
  return (
    <div
      onClick={() => !isread && readPush(id)}
      className={`border-solid p-4  ${
        isread ? 'cursor-not-allowed' : 'cursor-pointer bg-[#EBF6FD]'
      } flex gap-4`}
    >
      <div className="w-1/5 flex justify-center">
        {!image ? (
          <div className="w-[44px] h-[44px] bg-[#3386C8] rounded-full flex justify-center items-center">
            <NotificationsNoneIcon style={{ color: 'white', fontSize: 28 }} />
          </div>
        ) : (
          <img
            className="w-[44px] h-[44px] rounded-full"
            src={image}
            alt="noimage"
          />
        )}
      </div>
      <div className="w-full">
        <h4
          className={`text-sm ${
            isread
              ? 'font-semibold text-[#313131]'
              : 'font-medium text-[#1D2939]'
          }`}
        >
          {title}
        </h4>
        <div
          className={`text-xs py-2 ${
            isread ? 'text-[#52525B]' : 'text-[#313131]'
          }`}
        >
          {body}
        </div>
        <div className={`text-xs text-[#9C9C9C] `}>
          {date} - {time}
          {isread && (
            <DoneAllIcon
              style={{
                fontSize: 14,
                marginLeft: 12,
                marginBottom: 2,
                color: '#52525B',
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
