// services/axiosClient.js
import axios from 'axios';
import { toast } from 'react-toastify';
import { getParamConfig } from './nomal';
import { loginSuccess } from '../features/auth/authSlice';
import { store } from '../app/store';
import { setVariable } from './nomal';
const errorShown = new Set(); // Set to keep track of shown error status + endpoint

const createAxiosClient = (navigate,  customHeaders  = {}) => {
  const axiosClient = axios.create({
    baseURL: `${getParamConfig("API_URL")}/${getParamConfig("PORT")}`,
    headers: {
      'Content-Type': 'application/json',
      ...customHeaders
    },
  });
 
  // Cấu hình interceptor để thêm token vào tất cả các request
  axiosClient.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('authToken');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`; 
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
 
  // Cấu hình interceptor để xử lý lỗi từ server
  axiosClient.interceptors.response.use(
    (response) => response.data,
    (error) => {
      ;
      if (error.response) {
        const statusCode = error.response.status;
        const endpoint = error.response.config.url; // Lấy endpoint từ request config
        
        // Tạo một key để lưu trữ bao gồm endpoint và statusCode
        const errorKey = `${endpoint}_${statusCode}`;
  
        // if (!errorShown.has(errorKey)) {
        //   errorShown.add(errorKey); // Lưu key vào Set

          
        // }

        if (statusCode === 401) {
          toast.error('Unauthorized. Redirecting to login...');
          setVariable('authToken');
          store.dispatch( loginSuccess(null) )
        } else if (statusCode === 400) {
          toast.error(`Error: ${error.response.data.ErrorDesc}`);
        } else {
          toast.error(`Unexpected error: ${error.response.data.ErrorDesc}`);
        }
      }
      return Promise.reject(error);
    }
  );
 
  return axiosClient;
};
 
export default createAxiosClient;
 