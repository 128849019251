import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../css/Login.css'; // Sử dụng lại CSS cho form đăng ký, có thể đổi tên file để phù hợp hơn
import Logo from '../../assets/image/logologin.png';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { toast } from 'react-toastify';
import CryptoJS from 'crypto-js';
import createAxiosClient from '../../utils/axiosClient';
import { Button, CircularProgress } from '@mui/material';

const SignUp = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [fullName, setFullName] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const axiosClient = createAxiosClient(navigate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!username || !password || password !== confirmPassword) {
      toast.error('Please check your inputs.');
      setLoading(false);
      return;
    }
    try {
      const hashedPassword = CryptoJS.SHA256(password).toString();
      const body = {
        username,
        password: hashedPassword,
      };

      const response = await axiosClient.post(`auth/signup`, body);
      toast.success('Registration successful');
      navigate('/login');
    } catch (err) {
      console.log(err);
      toast.error('Registration failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="login-wrapper flex">
        <div className="form-login flex flex-col gap-6">
          <div>
            <img src={Logo} alt="Logo" className="logo my-0 mx-auto" />
          </div>
          <form
            className="form-main-login flex flex-col gap-7"
            onSubmit={handleSubmit}
          >
            <p className="text-[20px] text-white font-bold text-center uppercase">
              Sign Up
            </p>
            <div>
              <div className="mb-5">
                <label
                  htmlFor="fullName"
                  className="text-[14px] text-white font-semibold mb-1 inline-block"
                >
                  Full Name
                </label>
                <div className="custom-input-login">
                  <input
                    id="fullName"
                    type="text"
                    placeholder="Enter your full name"
                    value={fullName}
                    autoComplete="off"
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>
              </div>
              <div className="mb-5">
                <label
                  htmlFor="username"
                  className="text-[14px] text-white font-semibold mb-1 inline-block"
                >
                  Login Name
                </label>
                <div className="custom-input-login">
                  <input
                    id="username"
                    type="text"
                    placeholder="Login Name"
                    value={username}
                    autoComplete="off"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              </div>
              <div className="mb-5">
                <label
                  htmlFor="phoneNo"
                  className="text-[14px] text-white font-semibold mb-1 inline-block"
                >
                  Phone No
                </label>
                <div className="custom-input-login">
                  <input
                    id="phoneNo"
                    type="text"
                    placeholder="Enter phone no"
                    value={phoneNo}
                    autoComplete="off"
                    onChange={(e) => setPhoneNo(e.target.value)}
                  />
                </div>
              </div>
              <div className="mb-5">
                <label
                  htmlFor="email"
                  className="text-[14px] text-white font-semibold mb-1 inline-block"
                >
                  Email
                </label>
                <div className="custom-input-login">
                  <input
                    id="email"
                    type="text"
                    placeholder="Email"
                    value={email}
                    autoComplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="mb-5">
                <label
                  htmlFor="gender"
                  className="text-[14px] text-white font-semibold mb-1 inline-block"
                >
                  Gender
                </label>
                <div className="custom-input-login px-[20px]">
                  <select
                    id="gender"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    className="custom-select-login w-full bg-transparent text-white"
                  >
                    <option value="male" className="text-black">
                      Male
                    </option>
                    <option value="female" className="text-black">
                      Female
                    </option>
                  </select>
                </div>
              </div>
              <div className="mb-5">
                <label
                  htmlFor="address"
                  className="text-[14px] text-white font-semibold mb-1 inline-block"
                >
                  Address
                </label>
                <div className="custom-input-login">
                  <input
                    id="address"
                    type="text"
                    placeholder="Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: '#FFFFFF',
                color: 'rgba(47, 141, 227, 1)',
                fontWeight: 'bold',
                height: '50px',
                borderRadius: '10px',
                textTransform: 'none',
                boxShadow: 'none',
                '&:hover': { backgroundColor: '#FFFFFF' },
                '&:disabled': {
                  backgroundColor: '#DDDDDD',
                  color: 'rgba(47, 141, 227, 1)',
                },
              }}
              disabled={loading || !username || !password || !confirmPassword}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Sign Up'
              )}
            </Button>

            <div className="text-center mt-2 mb-1">
              <span className="text-white">Already have an account? </span>
              <a
                href="/login"
                className="text-white font-semibold hover:underline text-sm"
              >
                Login
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
