import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from '../components/Dashboard/Dashboard';
import History from '../components/History/History';
import ChatScreen from '../components/ChatApp/ChatScreen';
import Setting from '../components/SettingApp/Setting';
import Maincontent from '../components/Layout/Maincontent';
import Sidebar from '../components/Layout/Sidebar';
import Login from '../components/Auth/Login';
import ResetPassword from '../components/Auth/ResetPassword';
import ChangePassword from '../components/Auth/ChangePassword';
import SignUp from '../components/Auth/SignUp';
import Profile from '../components/Layout/Profile';
import { useSelector } from 'react-redux';
import PrivateRoute from './PrivateRoute';
// // Hàm kiểm tra trạng thái đăng nhập (giả sử bạn lưu token trong localStorage)
// const isAuthenticated = () => {
//   return !!localStorage.getItem('authToken'); // Kiểm tra xem token có tồn tại không
// };

export default function AppRoutes({ sidebarToggle, setSidebarToggle }) {
  const token = useSelector((state) => state.auth.Token); 
  return (
      <Routes>
        {/* Route cho trang đăng nhập */}
        <Route path="/login" element={<Login />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route
          path="/"
          element={
              <PrivateRoute>
                <Sidebar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
                <Maincontent sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
              </PrivateRoute>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="Chat" element={<ChatScreen />} />
          <Route path="History" element={<History />} />
          <Route path="Setting" element={<Setting />} />
          <Route path="Profile" element={<Profile />} />
          <Route path="ChangePassword" element={<ChangePassword />} />
        </Route>
      </Routes>
  );
}
