// src/features/auth/authSaga.js
import { takeLatest, call, put } from 'redux-saga/effects';
import { loginRequest, loginSuccess, loginFailure } from './authSlice';
import { loginApi } from '../../api/auth'; // Giả sử bạn có một API service
import Cookies from 'js-cookie';

function* loginSaga(action) {
  try {
    const { username, password } = action.payload;
    const response = yield call(loginApi, username, password);
    const { token } = response.data;
    
    // Lưu token vào cookie
    Cookies.set('token', token, { expires: 7 }); // Token sẽ hết hạn sau 7 ngày

    yield put(loginSuccess(token));
  } catch (error) {
    yield put(loginFailure(error.message));
  }
}

export function* watchLoginSaga() {
  yield takeLatest(loginRequest.type, loginSaga);
}