import React, { useState, useEffect, useRef } from 'react';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import Bot from '../../assets/image/bot.png';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DescriptionIcon from '@mui/icons-material/Description';
import Info from '../../assets/image/info.png';
import LeftChat from '../../assets/image/leftchat.svg';
import JoinChat from '../../assets/image/joinchat.svg';

import '../../css/ChatDetail.css';
function ChatDetail({
  data,
  typeChatToggle,
  setTypeChatToggle,
  toggleUserInfo,
  setToggleUserInfo,
}) {
  const [visibleMessageTimes, setVisibleMessageTimes] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setToggleUserInfo(false);
  }, [setToggleUserInfo]);

  const chatListRef = useRef(null);
  const NameUser = localStorage.getItem('nameUser');
  const getDisplayData = () => {
    if (!data || !Array.isArray(data.ChatData) || data.ChatData.length === 0) {
      return [];
    }
    const sortedChatData = data.ChatData.map((chat) => ({
      ...chat,
      date: new Date(chat.Timestamp).toLocaleDateString(),
      time: new Date(chat.Timestamp).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      }),
    })).sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

    let lastDate = null;
    const displayData = [];

    sortedChatData.forEach((chat, index) => {
      if (chat.date !== lastDate) {
        displayData.push({
          type: 'date',
          date: chat.date,
        });
        lastDate = chat.date;
      }
      displayData.push({
        type: 'message',
        role: chat.Role,
        message: chat.Message,
        time: chat.time,
        contentType: chat.ContentType,
        attachFiles: chat.AttachFile
          ? chat.AttachFile.map((file) => ({
              mediaId: file.MediaId,
              name: file.Name,
              type: file.Type,
              url: file.Url,
            }))
          : [],
        isLastBotMessage:
          index === sortedChatData.length - 1 ||
          sortedChatData[index + 1].Role !== chat.Role,
      });
    });

    return displayData;
  };

  const displayData = getDisplayData();

  const handleToggleChat = () => {
    setTypeChatToggle(!typeChatToggle);
  };

  const handleMessageClick = (index) => {
    setVisibleMessageTimes((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleUserInfo = () => {
    setToggleUserInfo((prev) => !prev);
  };

  const handleInfoClick = () => {
    handleClose();
    handleToggleUserInfo();
  };

  useEffect(() => {
    if (chatListRef.current) {
      chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
    }
  }, [displayData]);

  return (
    <div
      className={`chat-right ${
        typeChatToggle ? '' : 'active'
      } absolute right-0 transition-all duration-300 ease-in-out`}
    >
      <div
        className={`chat-detail-header flex justify-between ${
          !data || !Array.isArray(data.ChatData) || data.ChatData.length === 0
            ? 'hidden'
            : ''
        }`}
      >
        <div className="flex items-center gap-2">
          <div className="cursor-pointer" onClick={handleToggleChat}>
            {typeChatToggle ? (
              <KeyboardDoubleArrowLeftIcon />
            ) : (
              <KeyboardDoubleArrowRightIcon />
            )}
          </div>
          <p>{NameUser}</p>
        </div>
        <div className="flex items-center gap-4">
          <img
            src={Info}
            alt="More Info"
            className="w-[auto] h-[18px] cursor-pointer"
            onClick={handleInfoClick}
          />
        </div>
      </div>
      <div
        className={`chat-detail-main chat-detail-main-history ${
          toggleUserInfo ? 'active' : ''
        }`}
      >
        <ul ref={chatListRef} className="custom-scrollbar-chat">
          {displayData.map((item, index) => {
            if (item.type === 'date') {
              return (
                <div key={index} className="chat-date">
                  {item.date}
                </div>
              );
            } else {
              return (
                <li
                  key={index}
                  className={
                    item.contentType === 'Notice'
                      ? 'notice flex'
                      : item.role === 'user'
                      ? 'receiver'
                      : 'sender'
                  }
                >
                  <img
                    src={
                      item?.message?.includes('left')
                        ? LeftChat
                        : item?.message?.includes('joined')
                        ? JoinChat
                        : ''
                    }
                    alt="noimage"
                    className={
                      item.contentType !== 'Notice' ? 'hidden' : 'notice-image'
                    }
                  />
                  <span
                    className={`${
                      visibleMessageTimes[index]
                        ? 'opacity-100 translate-y-0'
                        : 'opacity-100 translate-y-0'
                    } transition-all duration-300 ${
                      item.contentType === 'Notice'
                        ? 'hidden'
                        : item.role === 'user'
                        ? 'receiver-message-time'
                        : 'sender-message-time'
                    }`}
                  >
                    {item.role === 'user'
                      ? data?.UserInfo?.Name
                      : item.role === 'bot'
                      ? 'AriTalk'
                      : `(Teller) ${localStorage.getItem('nameTeller') || ''}`}
                    , {' ' + item.time}
                  </span>
                  <div
                    className={
                      item.contentType === 'Notice'
                        ? 'notice-message'
                        : item.role === 'user'
                        ? 'receiver-message'
                        : item.role === 'bot'
                        ? 'bot-message'
                        : 'sender-message'
                    }
                    onClick={() => handleMessageClick(index)}
                  >
                    {item.role === 'bot' && item.isLastBotMessage && (
                      <div className="bot-avatar">
                        <img
                          src={Bot}
                          alt="Bot Avatar"
                          className="w-[20px] h-[20px] rounded-full"
                        />
                      </div>
                    )}
                    {item.message}
                    {item.attachFiles?.length > 0 && (
                      <div className="attachment-section">
                        {item.attachFiles.map((file, fileIndex) => (
                          <div key={fileIndex} className="attachment-item">
                            {!!file.type && file.type.startsWith('image/') ? (
                              <>
                                <img
                                  src={file.url}
                                  alt={file.name}
                                  className="attachment-image"
                                />
                                {/* <button
                            onClick={() => window.open(file.url, "_blank")}
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                              marginTop: "5px",
                            }}
                          >
                            <ArrowDownwardIcon />
                          </button> */}
                              </>
                            ) : (
                              <>
                                <div className="block-icon-file">
                                  <DescriptionIcon
                                    style={
                                      item.role === 'user'
                                        ? { color: '#000' }
                                        : { color: '#fff' }
                                    }
                                  />
                                </div>

                                <a
                                  href={file.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="attachment-file"
                                  style={
                                    item.role === 'user'
                                      ? {
                                          color: '#000',
                                          textDecoration: 'none',
                                        }
                                      : {
                                          color: '#fff',
                                          textDecoration: 'none',
                                        }
                                  }
                                >
                                  {file.name || 'Download File'}
                                </a>
                                {/* <button
                              onClick={() => window.open(file.url, "_blank")}
                              style={{
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                marginTop: "5px",
                              }}
                            >
                              <ArrowDownwardIcon />
                            </button> */}
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
}

export default ChatDetail;
