import React, { useState, useEffect } from 'react';
import AppRoutes from './router/Routes';
import '../src/css/App.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { fetchToken, onMessageListener } from './firebase';
import { OnReceivedPush } from './utils/utils';

const App = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);

  const getPushMess = async () => {
    const channel = new BroadcastChannel('push-notification');
    channel.addEventListener('message', (event) => {
      OnReceivedPush(event.data.data);
    });
    await fetchToken();
    onMessageListener((payload) => {
      OnReceivedPush(payload.data);
    });
  };
  useEffect(() => {
    getPushMess();
  }, []);

  return (
    <div id="main" className="relative">
      <AppRoutes
        sidebarToggle={sidebarToggle}
        setSidebarToggle={setSidebarToggle}
      />
      <ToastContainer theme="dark" />
    </div>
  );
};

export default App;
