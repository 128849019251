import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  layouts,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import createAxiosClient from '../utils/axiosClient';

ChartJS.register(ArcElement, Tooltip, Legend);

function Popular() {
  const navigate = useNavigate();
  const axiosClient = createAxiosClient(navigate);
  const [popular, setPopular] = useState([]);

  useEffect(() => {
    const fetchChatData = async () => {
      try {
        const body = {
          Type: 'popular',
          LastDay: '7',
        };

        const result = await axiosClient.post('/dashboard', body);
        setPopular(result.PopularCategories);
      } catch (error) {
        // toast.error(`Error fetching chat data: ${error.response ? error.response.data : error.message}`);
      }
    };
    fetchChatData();
  }, []);

  const data = {
    labels: popular.map((item) => item.Name),
    datasets: [
      {
        data: popular.map((item) => item.Value),
        backgroundColor: [
          '#003E99',
          '#004ECC',
          '#0061FF',
          '#0052CC',
          '#2684FF',
          '#4C9AFF',
          '#85B8FF',
          '#B3D4FF',
          '#DEEBFF',
        ],
        borderColor: ['#ffffff'],
        borderWidth: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: '70%',
  };

  const LegendItem = ({ color, label }) => (
    <div
      style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
    >
      <div
        style={{
          width: '10px',
          height: '10px',
          backgroundColor: color,
          marginRight: '5px',
          borderRadius: '50%',
        }}
      />
      <span
        style={{
          fontSize: '12px',
          color: '#313131',
          display: 'inline-block',
          // maxWidth: '50%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {label}
      </span>
    </div>
  );

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '220px',
        width: '100%',
        gap: '10%',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ height: 'auto', width: '44%', maxWidth: '220px' }}>
        <Doughnut data={data} options={options} />
      </div>
      <div
        style={{ display: 'flex' }}
        className="chart-popular-desc flex-col w-[44%] h-full"
      >
        <div style={{}}>
          {popular.slice(0, 3).map((item, index) => (
            <LegendItem
              key={index}
              color={data.datasets[0].backgroundColor[index]}
              label={item.Name}
            />
          ))}
        </div>
        <div style={{}}>
          {popular.slice(3).map((item, index) => (
            <LegendItem
              key={index + 3}
              color={data.datasets[0].backgroundColor[index + 3]}
              label={item.Name}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Popular;
