import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { setVariable, getVariable, getVariableTemp } from "./utils/nomal";
import { getFirebaseConfigdll } from "./modalAPI/FirebaseConfig";
import utils from "./utils/utils";

let firebaseApp;
let messaging;
const fetchFirebaseConfig = async () => {
  let firebaseConfig;
  const config = getVariable("firebaseConfig");
  if (!!config) {
    firebaseConfig = JSON.parse(config);
  } else {
    await getFirebaseConfigdll((data) => {
      firebaseConfig = data;
      setVariable('firebaseConfig', JSON.stringify(data))
    });
  }
  return firebaseConfig
};

export const fetchToken = async () => {
  
  let firebaseConfigObj;
  let firebaseVaidkey; 
  const  firebaseConfig = await fetchFirebaseConfig()
  if (firebaseConfig.FirebaseConfig) {
    firebaseConfigObj = firebaseConfig.FirebaseConfig;
    firebaseApp = initializeApp(firebaseConfigObj);
    messaging = getMessaging(firebaseApp);
    if (firebaseConfig.FirebaseKeypair) {
      firebaseVaidkey = firebaseConfig.FirebaseKeypair;
    } else {
      return;
    }
    return getToken(messaging, { vapidKey: firebaseVaidkey })
      .then((currentToken) => {
        if (currentToken) {
          const firebaseToken = getVariable('firebaseToken');
          if (!firebaseToken || firebaseToken !== currentToken) {
            setVariable("firebaseToken", currentToken);
          }
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        // console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
      });
  }
};

export const onMessageListener = (callback) => {
  onMessage(messaging, (payload) => {
    callback(payload);
  });
};
